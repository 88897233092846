<template>
<div>
  <div class="quizAdmin" v-if="obj.quiz && obj.quiz.length">
    <v-expansion-panels>
      <draggable class="text-left draggable" v-model='obj.quiz' handle=".handle">
        <v-expansion-panel v-for="(question,i) in obj.quiz" :key="i" class="">
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between">
              <v-icon class="mr-md-5 mr-2 handle cursor-pointer">mdi-arrow-up-down-bold</v-icon>
              <p class="ma-0 flex-grow-1">{{question.question || 'Question '+(i+1)}}</p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex align-center">
              <v-text-field v-model="question.question" class="mb-2 mr-2 " :placeholder="`Question ${i+1}`" ></v-text-field>
              <v-btn class="red lighten-2 white--text rounded-pill pa-0 flex-grow-0 wi-auto min-wi-sm-0 px-4" @click="deleteQuestion(i)">X</v-btn>
            </div>
            <div class="answers mt-5">
              <p class="mb-0">Réponses <em class="caption">(cocher la case si bonne réponse)</em>:</p>
              <div class="d-flex align-center" v-for="(answer,j) in question.answers" :key="j" >
                <v-text-field type="text" v-model="question.answers[j].text" :placeholder="`Réponse ${j+1}`"></v-text-field>
                <v-checkbox v-model="question.answers[j].right"></v-checkbox>
                <i class="fa fa-times cursor-pointer red--text" @click="deleteAnswer(question,j)"></i>
              </div>
              <v-btn class="btn greyBG mt-5 mb-10 secondary white--text" @click="addAnswer(question)">Ajouter une réponse</v-btn>
            </div>
            <v-textarea outlined rows="3" v-model="question.explanation" placeholder="Contenu explicatif"></v-textarea>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </draggable>
    </v-expansion-panels>
  </div>
  <v-btn @click="addQuestion()" class=" my-10 greyBG btn secondary white--text ">+ Ajouter une question de fin</v-btn>
</div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'quiz-creator',
  props: ['obj'],
    components:{
    draggable
  },
  methods: {
    addQuestion: function(){
      const emptyQ = {
        question: '',
        answers: [],
        explanation: ''
      }
      this.obj.quiz.push(emptyQ)
    },
    deleteQuestion: function(i){
      this.obj.quiz.splice(i,1)
    },
    addAnswer: function(question){
      if(!question.answers){question.answers = []}
      const emptyA = {
        text: '',
        right: false
      }
      question.answers.push(emptyA)
    },
    deleteAnswer: function(question,i){
      question.answers.splice(i,1)
    }
  }
}
</script>

<style lang="scss">
.quizAdmin {
  .v-expansion-panels {
    display: block!important;
  }
}
</style>