<template>
<div class="mx-auto py-3">
    <v-dialog v-model="choiceDialog" :width='$vuetify.breakpoint.mobile ? "90%" : "50%"'>
        <v-card class="demo-settings pa-5">
            <h4>Sélectionnez le partenaire pour cette démo</h4>
            <v-select v-model="partner" item-text="title" item-value="slug" :items="partnersList" placeholder="Choix du partenaire" @change="updatePartner"></v-select>
        </v-card>
    </v-dialog>
    <v-btn class="primary j-btn white--text cursor-pointer" @click='choiceDialog = true'>Démo</v-btn>
</div>
</template>

<script>
export default {
  name: 'demo-settings',
  data() {
    return {
        choiceDialog: false,
        partner: '',
        partnersList: [ 
        {title: 'AFTEC', slug: 'aftec'},
        {title: 'akimbo', slug: 'akimbo'},
        {title: 'Burgundy', slug: 'burgundy'},
        {title: 'Campus Eductive', slug: 'campus_eductive'},
        {title: 'Campus Mediaschool', slug: 'campus_mediaschool'},
        {title: 'EFAB', slug: 'efab'},
        {title: 'ENGDE', slug: 'engde'},
        {title: 'GIP FCIP', slug: 'gip_fcip'},
        {title: 'Grenoble Ecole Management', slug: 'grenoble_ecole_management'},
        {title: 'IPAG', slug: 'ipag'},
        {title: 'MBWAY', slug: 'mbway'},
        {title: 'My Business School', slug: 'my_business_school'},
        {title: 'NEOMA', slug: 'neoma'},
        {title: 'Paris School of Luxury', slug: 'paris_school_of_luxury'},
        {title: 'PPA', slug: 'ppa'},
        {title: 'Simplon', slug: 'simplon'},
        {title: 'Skema', slug: 'skema'},
        {title: 'TBS', slug: 'tbs'},
        {title: 'Vitawin', slug: 'vitawin'},
        {title: 'utc Alumni', slug: 'utc_alumni'},
      ]
    }
  },
  methods : {
      updatePartner: async function() {
        this.$store.dispatch('update_partner', {demo_partner:this.partner})
      }
  }
}
</script>

<style lang="scss">
.demo-settings {
    overflow-x: hidden;
}
</style>