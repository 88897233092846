<template>
    <v-list-item @click="$emit('hidedrawer')" :to="link">
        <div class="list-item rounded-lg d-flex wi-100">
          <v-list-item-action>
            <v-icon>mdi-{{icon}}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{text}}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="chevron">
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </div>
    </v-list-item>
</template>

<script>


export default {
  name: 'drawer-item',
  props:['link','text','icon','chevron']
}
</script>
