<template>
  <div :class="[{'scroll':scroll},'candidatures-list']">
    <h4 class="font-weigth-bold mb-2">Vos évènements concernant votre recherche :</h4>
    <v-divider></v-divider>
    <div class="d-flex align-center mb-4">
      <div style="height: 45px;width:45px;" class="pa-2 rounded-circle main-gradient-pastel">
        <img class="wi-100" src="/images/3d/calendar.png" alt="Calendrier"/>
      </div>
      <p class="font-weight-bold ml-3 mb-0">Prochains évènements</p>
    </div>
    <div v-if="soonEvents.length" class="d-flex flex-row flex-wrap justify-start candidatures-grid">
      <div v-for="cand of soonEvents" :key="cand._id" :class="['candidature white rounded-lg elevation-2 d-flex flex-column justify-center mb-5 pa-2 flex-grow-1']">
        <p class="main-gradient-pastel mb-4 pa-1 rounded-lg"><v-icon class="mr-1">mdi-calendar</v-icon>{{dateFormat(cand.soon.date)}}</p>
        <p class="mb-4 font-weight-bold">{{cand.soon.type}}</p>
        <div :class="[!cand.title && cand.company ? 'flex-column-reverse' : 'flex-column','d-flex']">
          <p class="mb-4 badge-content"><v-icon v-if="cand.title" class="mr-1">mdi-tie</v-icon><span>{{cand.title}}</span></p>
          <p class="mb-4 badge-content"><v-icon v-if="cand.company" class="mr-1">mdi-briefcase</v-icon><span>{{cand.company}}</span></p>
        </div>
        <v-spacer></v-spacer>
        <v-divider class="mt-0"></v-divider>
        <div :class="[!cand.soon.contact && cand.soon.contact_details ? 'flex-column-reverse' : 'flex-column','d-flex']">
          <p v-if="!cand.soon.contact && !cand.soon.contact_details" class="caption">Aucune information sur le contact</p>
          <p v-else class="mb-4 badge-content"><v-icon v-if="cand.soon.contact" class="mr-1">mdi-account</v-icon><span>{{cand.soon.contact}}</span></p>
          <p class="mb-4 badge-content"><v-icon v-if="cand.soon.contact_details" class="mr-1">mdi-comment-account-outline</v-icon><span>{{cand.soon.contact_details}}</span></p>
        </div>
        <v-divider class="mt-0"></v-divider>
        <router-link :to="`/user/candidatures/${cand._id}`" class="mb-0 text-caption view-more">Voir cette candidature <i class="fa fa-arrow-right"></i></router-link>
      </div>
    </div>
    <p v-else class="caption grey--text">Aucun évènement</p>
    <v-divider></v-divider>
    <div class="d-flex align-center mb-4">
      <div style="height: 45px;width:45px;" class="pa-2 rounded-circle main-gradient-pastel">
        <img class="wi-100" src="/images/3d/megaphone.png" alt="Megaphone"/>
      </div>
      <p class="font-weight-bold ml-3 mb-0">À relancer</p>
    </div>
    <div v-if="notifyEvents.length" class="d-flex flex-row flex-wrap justify-start  candidatures-grid">
      <div v-for="cand of notifyEvents" :key="cand._id" :class="['candidature white rounded-lg elevation-2 d-flex flex-column justify-center mb-5 pa-2']">
        <div class="d-flex align-center mb-4">
          <p class="BGalert pa-1 ma-0 rounded-lg badge min-he-content mr-2"><v-icon small color="white">mdi-bell</v-icon></p>
          <p class="mb-0 caption">Dernier évènement : <span class="font-weight-bold">{{cand.events[0].type}}</span> le <span class="font-weight-bold">{{dateFormat(cand.events[0].date)}}</span></p>
        </div>
        <v-spacer></v-spacer>
        <v-divider class="mt-0"></v-divider>
        <div :class="[!cand.title && cand.company ? 'flex-column-reverse' : 'flex-column','d-flex']">
          <p v-if="!cand.title && !cand.company" class="caption">Aucune information sur la candidature</p>
          <p v-else class="mb-4 badge-content"><v-icon v-if="cand.title" class="mr-1">mdi-tie</v-icon><span>{{cand.title}}</span></p>
          <p class="mb-4 badge-content"><v-icon v-if="cand.company" class="mr-1">mdi-briefcase</v-icon><span>{{cand.company}}</span></p>
        </div>        
        <v-divider class="mt-0"></v-divider>
        <router-link :to="`/user/candidatures/${cand._id}`" class="mb-0 text-caption view-more">Voir cette candidature <i class="fa fa-arrow-right"></i></router-link>
      </div>
    </div>
    <p v-else class="caption grey--text">Aucune candidature à relancer</p>
  </div>
</template>

<script>
export default {
  name: 'candidatures-list',
  props: {
    soonEvents: {type: Array},
    notifyEvents: {type: Array},
    scroll: { type: Boolean, default: () => { return true}}
  },
  methods: {
    dateFormat(date) {
      const options = { weekday: 'short', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      date = new Date(date)
      date = date.toLocaleDateString('fr-FR', options)
      const parts = date.split(', ')
      return `${parts[0]} (${parts[1]})`
    }
  }
}
</script>

<style lang="scss">
.candidatures-list {
  .candidatures-grid {
    .candidature {
      $margin: 16px;
      width: 100%;
      margin: 0 0 $margin 0;
      @include noPhone {
        $margin: calc(10% / 3 / 2);
        width: 30%;
        margin: 0 $margin $margin $margin;
      }
      .badge-content {
        display: flex;
        &.caption {
          padding-top: 0.25rem;
        }
        span {
          word-break: break-word!important;
          &:empty::before {
            content: "";
            display:inline-block;
          }
        }
      }
      .view-more {
        transition: all ease 0.3s;
        &:hover {
          transform: translateX(0.5rem);
        }
      }
    }
  }
}
</style>