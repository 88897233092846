<template>
    <div>
        <v-app-bar color="transparent" dense>
            <v-app-bar-nav-icon color="white" @click.stop="$emit('drawer')"></v-app-bar-nav-icon>
            <v-toolbar-title><router-link to="/">
                <img v-if="demo" class="ma-0 logo" :src="$store.getters.getDemoPartner.path" alt="MyJobAcademy partner demo"/>
                <img v-else-if="partner && !!$store.getters.getPartner" class="ma-0 logo" :src="$vuetify.breakpoint.xs ? $store.getters.getPartner.responsive : $store.getters.getPartner.path" alt="MyJobAcademy partner"/>
                <img v-else class="ma-0 logo" alt="Logo MyJobAcademy" src="/images/logo/jobacademy.png">
            </router-link></v-toolbar-title>
            <v-slide-x-transition v-if="collectiveInfos">
                <j-collectives-infos class="collectives-desktop" v-if="$store.state.collectivesInfos.display" v-bind="this.$store.state.collectivesInfos"></j-collectives-infos>
            </v-slide-x-transition>
            <v-spacer></v-spacer>
            <div class="hidden-sm-and-down mr-5" v-if="!this.$store.getters.isAuthenticated">
                <router-link class="white_ mr-5" to="/login">Connexion</router-link>
                <router-link v-if="registerApp" class="white_ mr-5" to="/register-infos">Inscription</router-link>
            </div>
            <div v-if="$store.getters.isAuthenticated && $store.getters.isProfileLoaded">
              <router-link to="/user/me" class="account-link d-flex align-center text-white caption">
                <v-avatar v-if="$store.getters.getPicture" size="32">
                  <img :src="$store.getters.getPicture" alt="Photo de profil"/>
                </v-avatar>
                <div v-else>
                  <span>
                      {{$store.getters.getFirstname[0].toUpperCase()}}{{$store.getters.getLastname[0].toUpperCase()}}
                  </span>
                </div>
                <p class="hidden-sm-and-down ma-0 ml-2">Mon compte</p>
                <span v-if="$store.getters.getNotifications.length" class="j-badge ml-1">{{$store.getters.getNotifications.length}}</span>
              </router-link>
            </div>
        </v-app-bar>
    </div>
</template>

<script>
import { registerApp, collectiveInfos, partner, demo } from '@/../config'

export default {
  name: 'navbar',
  data() {
    return {
      registerApp: registerApp,
      collectiveInfos: collectiveInfos,
      demo: demo,
      partner: partner
    }
  }
}
</script>

<style lang="scss">
.v-app-bar{
  box-shadow: none !important;
    .v-toolbar {
        &__content {
        padding-right: 3rem;
        padding-left: 3rem;
        @include phone {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .v-toolbar__title {
          @include phone {
            padding-left: 0!important;
          }
        }
      }
    }
    .logo {
        height: 1.5rem;
        &.partner {
          @include phone {
            height: 1rem;
          } 
        }
    }
    .account-link {
      * {
        transition: all ease 0.3s;
      }
      &:hover {
        @include noPhone{
          color: $primary!important;
          >div {
              background: $primary;
          } 
          >div >span {
              color: white!important;
          }
        }
      }
      >div {
        font-weight: bold!important;
        background: whitesmoke;
        border-radius: 100%;
        width: 2rem;
        height: 2rem;
        position: relative;
        span {
          display: block;
          color: $secondary!important;
          font-size: 1rem;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }

}
#navbar{
    padding: 1em!important;
    &.bg-info{
        background-color: whitesmoke!important;
    }
    .navbar-brand{
        img{
            height:1.5em;
        }
    }
    #nav-collapse{
        @include noPhone{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .navbar-nav{
                flex: 1;
                display: flex;
                justify-content: flex-end;
                span{
                    display: flex;
                }
                .nav-item{
                    margin-right: 1em;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .nav-item{
        position: relative;
        .b-nav-dropdown{
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            @include phone{
                &.show{
                    position: relative;
                    >a{
                        &::after{
                            top: 0;
                            z-index: 10000;
                            transform: translate(0, -50%) rotate(180deg);
                        }
                    }
                    .dropdown-menu{
                        position: relative!important;
                        display: flex;
                        flex-direction: column;
                        border: none;
                        outline: none!important
                    }
                }
            }
            >a{
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(50%, -50%);
                @include phone{
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    top: 0;
                    transform: none;
                    &::after{
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0,-50%);
                    }
                }

            }
            .dropdown-menu{
                position: absolute;
                left: 0;
                top: 100%!important;
                padding: 0;
                height: fit-content;
                flex-direction: column;
            }
        }
        &:hover{
            .dropdown-menu{
                @include noPhone{
                    display: flex;
                }
            }
        }
    }
    .nav-link {
        display: flex;
        @include phone{
            flex-direction: column;
            align-items: flex-start;
        }
        a{
            color: $secondary;
        }
    }
    a.router-link-exact-active{
        font-weight: bold;
    }
}

.navbar.navbar-dark .navbar-toggler{
    .navbar-toggler-icon{
        background-image: none!important;
        border-top: grey solid 3px;
        border-bottom: grey solid 3px;
        position: relative;
        height: 1em;
        transition: all ease 0.3s;
        &::after,&::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            background: grey;
            height: 3px;
            transform-origin: center;
            transform: translate(0, -50%);
            transition: all ease 0.3s;
        }
    }
    &[aria-expanded=true]{
        .navbar-toggler-icon{
            border-top: transparent solid 3px;
            border-bottom: transparent solid 3px;
            &::after{
                transform: translate(0,-50%) rotate(45deg);
            }
            &::before{
                transform: translate(0,-50%) rotate(-45deg);
            }
        }
    }
}
.collectives-desktop {
    @include phone {
        display: none;
    }
}
</style>
