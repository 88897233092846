<template>
  <div>
    <p class="h4 primary--text mb-0">Choix du cursus :</p>
    <v-select label="Cursus" v-model="user.curriculum" @change="user.speciality = null" :items="curriculums" item-text="title" item-value="_id" class="mt-2"></v-select>
    <v-select label="Spécialités selon le cursus" v-model="user.speciality" :items="getSpecialities" item-text="title" item-value="_id" class="mt-2"></v-select>
  </div>
</template>

<script>
import { apiURL } from '@/../config'
import axios from 'axios'

export default {
  props: ['user'],
  name: 'curriculum-choice',
  data() {
    return {
      curriculums: []
    }
  },
  async created() {
    const res = await axios.get(apiURL+'auth/admin/curriculums')
    this.curriculums = res.data.curriculums.sort((a, b) => {
        if ( a.title < b.title ) return -1
        if ( a.title > b.title ) return 1
        return 0
      })
  },
  computed: {
    getSpecialities() {
      const c = this.user.curriculum && this.curriculums.find(c => { return c._id === this.user.curriculum })
      return c ? c.specialities.sort((a, b) => {
        if ( a.title < b.title ) return -1
        if ( a.title > b.title ) return 1
        return 0
      }) : []
    }
  }
}
</script>
