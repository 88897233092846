<template>
  <div :style="`--bottom:${bottom}rem;`" class="j-helper-chat d-flex flex-column">
      <div v-if="helpChat" :class="[{'opened':scalling},'j-helper-content rounded-lg pa-2 d-flex elevation-1']">
        <div class="min-wi-content">
          <div class="white rounded-xl py-2 px-3 mb-2">
            <p class="mb-0 font-weight-bold">{{text}}</p>
          </div>
          <div class="rounded-lg video-container">
            <iframe class="vimeo-iframe" :src="`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0`" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
        <v-avatar size="35" class="BGLight-secondary ml-2 ml-md-4">
          <img :src="`/images/3d/coach_${coachIndex}.png`" alt="Coach">
        </v-avatar>
      </div>
    <v-btn @click="toggle(!helpChat)" :small="$vuetify.breakpoint.smAndDown" :class="[{'radiation':!helpChat},'text-grey ml-auto mt-4']">tuto</v-btn>
  </div>
</template>

<script>
export default {
  name: 'helper-chat',
  props: {
    text: { type: String },
    videoId: { type: String },
    bottom: { type: Number, default: () => { return 1} }
  },
  data() {
    return {
      helpChat: false,
      scalling: false,
      coachIndex: 1
    }
  },
  created () {
    this.coachIndex = Math.floor(Math.random()*4+1)
    setTimeout(() => {
      this.toggle(true)
      setTimeout(() => {
        this.toggle(false)
      }, 3000)
    }, 500)
  },
  methods: {
    toggle(event) {
      if(event) {
        this.helpChat = true
        setTimeout(() => { this.scalling = true }, 50)
      }
      else {
        this.scalling = false
        setTimeout(() => { this.helpChat = false }, 550)
      }
    }
  }
}

</script>

<style lang="scss">
.j-helper-chat {
    position: fixed;
    z-index: 210;
    bottom : var(--bottom);
    right: 1rem;
    .j-helper-content {
      background-color: #eef2f5;
      transform: scale(0);
      margin-bottom: -52px;
      transition: all 0.5s ease;
      transform-origin: bottom right;
      &.opened {
        transform: scale(1);
        margin-bottom: 0;
      }
      .video-container {
        position: relative;
        overflow: hidden;
        width: 75vw;
        aspect-ratio: 16/9;
        @include noPhone {
          width: 25vw;
        }
        .vimeo-iframe {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
    .v-btn {
      background-color: #e9e9e9;
      border-radius: 50px 25px 5px 50px;
      font-weight: bold;
      &:after, &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 50px 25px 5px 50px;
        animation : btn-radiation 1.8s ease-in-out infinite;
        display: none;
      }
      &::before{
        animation-delay: 0.4s;
      }
      &.radiation{
        &::after,&::before{
          display: block;
          @include phone {
            display: none;
          }
        }
      }
    }
  }
</style>