<template>
    <div id="dialog" >
        <div class="box">
            <div class="text-grey text-center box-content">
                <h2>{{content.title}}</h2>
                <p>{{content.message}}</p>
            </div>
            <div class="wi-100 text-center">
                <v-btn :class="[(content.color ? content.color : 'secondary'),'j-btn white--text']" @click="callback()">{{content.button || "Fermer"}}</v-btn>
            </div>
            <div v-if="content.alt && content.alt.length" class="d-flex justify-center">
                <p @click="altCallback()" class="grey--text hover-opacity cursor-pointer mt-3 mb-0 text-decoration-underline">{{content.alt}}</p>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'dialog-box',
  props:['content','cb','altCb'],
  mounted(){
  },
  methods:{
      handleEnter: function(e){
          if(e.code == "Enter"){
              this.callback()
          }
      },
      callback:function(){
          if(this.cb){
              this.cb()
          }
          this.$store.dispatch('close_dialog')
      },
      altCallback:function(){
          if(this.altCb){
              this.altCb()
          }
          this.$store.dispatch('close_dialog')
      }
  }
}
</script>

<style lang="scss">
#dialog{
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    .box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1000000;
        background: whitesmoke;
        padding: 2em;
        border-radius: 1em;
        box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
        @include phone{
            width: 80%;
            padding: 1em 0;
        }
        .box-content {
        // padding: 2em 4em;
            @include phone{
                // padding: 2em;
            }
        }
    }

}
</style>
