module.exports = {
  apiURL: process.env.VUE_APP_API_URL,
  jobEden: process.env.VUE_APP_MODS.includes('jobeden'),
  myjobacademy: process.env.VUE_APP_MODS.includes('myjobacademy'),
  demo: process.env.VUE_APP_MODS.includes('demo'),
  partner: process.env.VUE_APP_PARTNER,
  partnerLogo: process.env.VUE_APP_LOGO,
  partnerLogoMobile: process.env.VUE_APP_LOGO_MOBILE,
  schoolApp: process.env.VUE_APP_MODS.includes('schoolapp'),
  coach: process.env.VUE_APP_MODS.includes('coach'),
  collective: process.env.VUE_APP_MODS.includes('collective'),
  logbookAccess: process.env.VUE_APP_MODS.includes('logbookaccess'),
  stats: process.env.VUE_APP_MODS.includes('stats'),
  myjobacademyFront: process.env.VUE_APP_MYJOBACADEMY_FRONT,
  admin: process.env.VUE_APP_ADMIN === 'true',
  env: process.env.VUE_APP_ENV
}
