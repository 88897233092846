import Vue from 'vue'

const state = { demo_partner: localStorage.getItem('partner') || 'akimbo' }

const demoList = [
  {path: 'https://i.ibb.co/BBh8Jch/aftec.png', slug: 'aftec'},
  {path: 'https://i.ibb.co/D9FmhdZ/akimbo.png', slug: 'akimbo'},
  {path: 'https://i.ibb.co/sjSwyWF/burgundy.png', slug: 'burgundy'},
  {path: 'https://i.ibb.co/BPXmMGG/campus-eductive.png', slug: 'campus_eductive'},
  {path: 'https://i.ibb.co/dgTc5MF/campus-mediaschool.png', slug: 'campus_mediaschool'},
  {path: 'https://i.ibb.co/M1C7T8n/efab.png', slug: 'efab'},
  {path: 'https://i.ibb.co/ggKzmYt/engde.png', slug: 'engde'},
  {path: 'https://i.ibb.co/Lh6KkSZ/gip-fcip.png', slug: 'gip_fcip'},
  {path: 'https://i.ibb.co/5XD83g0/grenoble-ecole-de-management.png', slug: 'grenoble_ecole_management'},
  {path: 'https://i.ibb.co/G7VqHfY/ipag.png', slug: 'ipag'},
  {path: 'https://i.ibb.co/68Y5MWS/mbway.png', slug: 'mbway'},
  {path: 'https://i.ibb.co/kxwd6Kp/mybs.png', slug: 'my_business_school'},
  {path: 'https://i.ibb.co/px9Kmkz/neoma.png', slug: 'neoma'},
  {path: 'https://i.ibb.co/6HPR2j7/paris-school-of-luxury.png', slug: 'paris_school_of_luxury'},
  {path: 'https://i.ibb.co/4FszV2N/ppa.png', slug: 'ppa'},
  {path: 'https://i.ibb.co/m5v2kkY/simplon.png', slug: 'simplon'},
  {path: 'https://i.ibb.co/WtgcXHW/skema.png', slug: 'skema'},
  {path: 'https://i.ibb.co/GkhTrNn/tbs.png', slug: 'tbs'},
  {path: 'https://i.ibb.co/QNFWjgs/utc-alumni.png', slug: 'utc_alumni'},
  {path: 'https://i.ibb.co/gmJsyqL/vitawin.png', slug: 'vitawin'},
]

const getters = {
  getDemoPartner: state => demoList.find(demo => { return demo.slug === state.demo_partner })
}

const actions = {
    update_partner: async ({ commit },payload) => {
      commit('demo_request', payload)
    }
}

const mutations = {
  demo_request: (state, payload) => {
    localStorage.setItem("partner", payload.demo_partner)
    Vue.set(state,'demo_partner', payload.demo_partner)
  }
}

export default {
    state,
    getters,
    actions,
    mutations
}

