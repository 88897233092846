export default {
  journey: {
    subject: "Relance concernant votre parcours en ligne pour la recherche d’emploi",
    content: "Cher/Chère [nom de l’étudiant],\n\nNous espérons que vous vous portez bien et que vous avancez dans votre recherche d’emploi. Nous vous écrivons aujourd’hui pour vous rappeler que vous avez commencé un parcours en ligne sur MyJobAcademy pour vous aider dans cette recherche, mais que vous ne l’avez pas terminé dans les délais impartis.\nNous comprenons que la recherche d’emploi peut être difficile et qu’il y a souvent de nombreux obstacles à surmonter. C’est pourquoi nous avons mis en place ce parcours en ligne pour vous aider à acquérir les compétences nécessaires pour trouver le travail que vous cherchez.\nCependant, pour tirer le meilleur parti de ce parcours, il est important de le suivre dans les délais impartis. Si vous avez des difficultés à le terminer, nous sommes là pour vous aider et vous accompagner. N’hésitez pas à nous contacter si vous avez besoin d’aide ou si vous avez des questions.\nNous vous encourageons vivement à reprendre votre parcours en ligne dès que possible afin de maximiser vos chances de réussite dans votre recherche d’emploi. Si vous avez besoin de plus de temps pour le terminer, veuillez nous en informer afin que nous puissions prendre cela en compte.\nNous vous remercions pour votre temps et votre considération, en espérant avoir de vos nouvelles bientôt.\n\nCordialement,"
  },
  resume: {
    subject: "Relance pour votre CV",
    content: "Cher/Chère [nom de l’étudiant],\n\nNous espérons que vous vous portez bien. Nous avons remarqué que vous n’avez pas encore créé votre CV sur MyJobAcademy, et nous souhaitons vous rappeler que c’est une étape importante pour vos futures recherches d’emploi.\nNous comprenons que la création d’un CV peut sembler intimidante, mais c’est une étape cruciale pour présenter vos compétences et vos réalisations professionnelles aux employeurs potentiels. En créant votre CV sur MyJobAcademy, vous aurez également la possibilité de le partager avec les entreprises partenaires de notre école, ce qui pourrait augmenter vos chances de trouver un emploi qui correspond à vos objectifs de carrière.\nNous vous encourageons vivement à créer votre CV dès que possible, et nous sommes là pour vous aider si vous avez besoin d’aide ou de conseils pour le faire. Si vous rencontrez des difficultés ou si vous avez des questions, n’hésitez pas à nous contacter. Nous sommes là pour vous aider à réussir.\n\nCordialement,"
  },
  contractNear: {
    subject: "Votre contrat se terminant dans - de 3 mois",
    content: "Cher/Chère [nom de l’étudiant],\n\nNous espérons que ce message vous trouve en bonne santé et que tout se passe bien pour vous.\nNous tenions à vous rappeler que votre contrat de travail prendra fin dans moins de 3 mois. Nous comprenons que cela peut être une source d’inquiétude et de stress pour vous, mais nous tenions à vous rappeler que notre école est là pour vous aider si vous en avez besoin.\nEn tant qu’étudiant de notre école, vous avez à votre disposition la plateforme MyJobAcademy qui propose des outils et des conseils pour vous aider à trouver un nouvel emploi et à vous préparer pour vos entretiens. Cette plateforme est accessible à tout moment et gratuitement, vous pouvez donc l’utiliser autant que vous le souhaitez.\nDe plus, notre équipe est à votre disposition pour vous aider à élaborer votre CV, à vous préparer pour les entretiens d’embauche et à vous orienter vers les opportunités d’emploi qui pourraient vous correspondre.\nNous vous encourageons à utiliser ces ressources et à prendre le temps de préparer votre prochaine étape professionnelle. N’hésitez pas à contacter notre équipe si vous avez des questions ou si vous avez besoin d’aide.\nNous vous souhaitons bonne chance pour votre recherche d’emploi et nous restons à votre disposition pour vous accompagner dans cette étape importante de votre carrière.\n\nCordialement,"
  },
  searching: {
    subject: "Ressources et soutien pour votre recherche de stage/alternance/apprentissage ou premier emploi",
    content: "Cher/Chère [nom de l’étudiant],\n\nNous espérons que tout se passe bien pour vous et que votre recherche de stage/alternance/apprentissage ou de premier emploi avance positivement. Nous sommes conscients que cette période peut être stressante et nous sommes là pour vous aider à chaque étape de votre recherche.\nNous souhaitons vous rappeler que notre plateforme MyJobAcademy est à votre disposition pour vous fournir de nombreuses ressources, de l’aide, des outils et des conseils pour optimiser votre recherche d’emploi. Vous pouvez notamment y créer votre CV et votre lettre de motivation, préparer vos entretiens et bénéficier d’un accompagnement personnalisé pour améliorer votre profil et votre candidature.\nMyJobAcademy est conçue pour répondre à vos besoins spécifiques et vous aider à atteindre vos objectifs professionnels. Nous vous encourageons donc à explorer la plateforme et à profiter de toutes les ressources à votre disposition.\nN’oubliez pas que notre équipe est également là pour vous soutenir et répondre à toutes vos questions. N’hésitez pas à nous contacter si vous avez besoin d’aide ou de conseils supplémentaires.\nNous vous souhaitons bonne chance dans votre recherche d’emploi et nous espérons que vous trouverez rapidement une opportunité professionnelle satisfaisante.\n\nCordialement,"
  },
  logbook: {
    subject: "Relance concernant votre tableau de suivi de candidatures sur MyJobAcademy",
    content: "Cher/Chère [nom de l’étudiant],\n\nNous espérons que tout se passe bien pour vous et que vous avez commencé à rechercher des stages, des alternances ou votre premier emploi.\nCependant, nous avons remarqué que vous n’avez pas encore complété votre tableau de suivi de candidatures en ligne sur la plateforme MyJobAcademy. Nous tenons à vous rappeler que cela est très important pour que notre école puisse suivre votre avancée dans vos recherches et vous aider si besoin.\nNous vous encourageons donc à prendre quelques minutes pour compléter ce tableau directement sur MyJobAcademy.\nEn effet, celui-ci offre différentes options très utiles telles que la possibilité d’envoyer des mails de relance/remerciement aux recruteurs, d’ajouter des notes suite à vos entretiens, et surtout de gérer toutes vos candidatures au même endroit, ce qui vous permet de ne rien rater et de paraître professionnel aux yeux des recruteurs.\nNous sommes convaincus que cela vous sera très utile dans votre recherche d’emploi et nous sommes à votre disposition si vous avez des questions ou des difficultés à remplir le tableau.\nNous vous remercions de votre attention et nous espérons que vous prendrez le temps de compléter le tableau dès que possible.\n\nCordialement,"
  },
  activation: {
    subject: "Relance concernant l'activation de votre compte MyJobAcademy",
    content: `Cher/Chère [nom de l’étudiant],\n\nNous espérons que tout se passe bien pour vous.\n\nNotre établissement vous met à disposition le service MyJobAcademy.\n\nCette solution vous accompagne dans toutes les étapes de votre recherche d'emploi.\n\nElle vous propose de nombreux outils comme un un parcours e-learning, CV en ligne, lettre de motivation, outils pour préparer votre présentation et des questions où encore un tableau de suivi des candidatures.\n\nNous vous remercions d'activer votre compte MyJobAcademy au plus vite.\n\nCliquez sur le lien ci-dessous afin d'activer votre compte.\n\nCordialement,`
  },
  company: {
    subject: "Proposition de profils d’étudiants en lien avec vos attentes",
    content: "Cher/Chère [nom de l’entreprise partenaire],\n\nNous espérons que tout se passe bien pour vous et votre entreprise.\nNous tenions à vous remercier pour votre précieuse collaboration avec notre école, ainsi que pour votre soutien dans la formation de nos étudiants.\nDans ce contexte, nous avons identifié des profils d’étudiants qui, selon nous, pourraient correspondre à vos attentes. Ces étudiants ont suivi des cours et des stages en lien avec votre domaine d’activité et possèdent des compétences qui pourraient être bénéfiques pour votre entreprise.\nNous souhaiterions donc vous proposer ces profils et organiser une rencontre afin de discuter de ces étudiants en détail. Nous sommes convaincus que cette collaboration pourrait être bénéfique pour toutes les parties et nous sommes à votre disposition pour répondre à toutes vos questions ou demandes supplémentaires.\nVous trouverez les CVs en pièce jointe. Nous espérons que cette proposition retiendra votre attention et que nous pourrons poursuivre notre collaboration fructueuse.\nNous vous remercions de votre attention et nous sommes impatients de discuter avec vous de cette proposition.\n\nCordialement,"
  },
  trial: {
    subject: "Plateforme MyJobAcademy - Ressources pour votre recherche d’alternance",
    content: "Cher/Chère [Nom du potentiel futur étudiant],\n\nNous sommes ravis de constater que vous êtes intéressé(e) par notre école. Nous avons hâte de vous accueillir parmi nos étudiants. Nous comprenons que vous devez trouver une alternance pour pouvoir être accepté(e) et nous sommes là pour vous aider à chaque étape de votre recherche.\nNous souhaitons vous présenter notre plateforme MyJobAcademy qui vous permettra d’accéder à de nombreuses ressources, de l’aide, des outils et des conseils pour optimiser votre recherche d’alternance. Vous pourrez notamment y créer votre CV et votre lettre de motivation, préparer vos entretiens et bénéficier d’un accompagnement personnalisé pour améliorer votre profil et votre candidature.\nNotre équipe est là pour vous guider dans votre recherche d’alternance et pour répondre à toutes vos questions. Nous avons hâte de travailler avec vous pour vous aider à atteindre vos objectifs professionnels.\nNous espérons que vous explorerez la plateforme MyJobAcademy et que vous y trouverez toutes les ressources nécessaires pour réussir votre recherche d’alternance. N’hésitez pas à nous contacter si vous avez des questions ou si vous avez besoin d’aide supplémentaire.\n\nCordialement,"
  }
}