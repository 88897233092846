<template>
  <div id="userForm">
    <v-form :class="[{'form-inline':inline},'d-flex flex-column justify-center']" @submit.prevent="onSubmit">
      <v-text-field  required :disabled="disabled.includes('firstname')" @focus="noError" @keydown="noError" v-if="usage.includes('firstname')" id="inline-form-input-name" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" :outlined="!solo" :solo="solo" placeholder="Prénom" v-model ='user.firstname' ></v-text-field>
      <v-text-field  required :disabled="disabled.includes('lastname')" @focus="noError" @keydown="noError" v-if="usage.includes('lastname')" id="inline-form-input-lastname" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" :outlined="!solo" :solo="solo" placeholder="Nom" v-model ='user.lastname'></v-text-field>
      <v-text-field required :disabled="disabled.includes('email')" @focus="noError" @keydown="noError" v-if="usage.includes('email')"  id="inline-form-input-email" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" :outlined="!solo" :solo="solo"  type="email" placeholder="Email" v-model='user.email'></v-text-field>
      <v-text-field  required :disabled="disabled.includes('phone')" @focus="noError" @keydown="noError" v-if="usage.includes('phone')"  id="inline-form-input-phone" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" :outlined="!solo" :solo="solo" type="phone" placeholder="Téléphone" v-model='user.phone'></v-text-field>
      <v-text-field  required :disabled="disabled.includes('password')" @focus="noError" @keydown="noError" v-if="usage.includes('password')" id="inline-form-input-password" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" :outlined="!solo" :solo="solo" :type="displayPass ? 'text' : 'password'" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPass = !showPass" placeholder="Mot de passe" v-model='user.password'></v-text-field>
      <v-text-field  required :disabled="disabled.includes('confirmPassword')" @focus="noError" @keydown="noError" v-if="usage.includes('confirmPassword')" id="inline-form-input-password-confirm" class="rounded-lg mb-2 mr-sm-2 mb-sm-0" :outlined="!solo" :solo="solo" :type="displayPass ? 'text' : 'password'" :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPass = !showPass" placeholder="Confirmation mot de passe" v-model='user.confirmPassword'></v-text-field>
      <div v-if="usage.includes('trial')">
        <v-switch @focus="noError" id="inline-form-input-trial" label="Utilisateur en essai" v-model="user.trial.isOnTrial"></v-switch>
        <span v-if="user.trial.isOnTrial">
          <p>Date de fin d'essai</p>
          <j-date-picker class="wi-20" :date="user.trial.end" @change="user.trial.end = $event"></j-date-picker>
        </span>
      </div>
      <div v-if="usage.includes('optin')" class="wi-100 mt-4 d-flex caption align-center" >
        <v-checkbox @click="noError" class="caption ma-0 mb-0 pa-0" v-model="user.optin" ></v-checkbox>
        <p class="ma-0">J'accepte les <a class="ml-1" target="_blank" href="https://jobtimise.com/conditions-dinscription/">conditions d'inscription de Jobtimise</a>.</p>
      </div>
      <div v-if="usage.includes('optinMJA')" class="wi-100 mt-4 d-flex caption align-center" >
        <v-checkbox @click="noError" class="caption ma-0 mb-0 pa-0" v-model="user.optin" ></v-checkbox>
        <p class="ma-0">J'accepte les <span @click="displayOptin = !displayOptin" class="cursor-pointer primary--text hover-opacity">conditions d'inscription de MyJobAcademy</span>.</p>
      </div>
      <v-expand-transition>
        <div v-if="usage.includes('optinMJA') && displayOptin" class="display-optin main-gradient-pastel rounded-lg pa-4 mt-4">
          <p class="text-pre-wrap text-justify white pa-2 ma-0">
            {{`
            1. Collecte des données\n
              \t1.1 Cookies pour les sessions : Le Fournisseur de services peut utiliser des cookies pour les sessions afin de faciliter l'interaction de l'Utilisateur avec le service en ligne. Ces cookies sont utilisés pour maintenir la connexion de l'Utilisateur et pour permettre une utilisation fluide de l'application.\n
              \t1.2 Stockage de l'information de visualisation de la notification d’un nouvel atelier collectif : Le Fournisseur de services peut collecter et stocker des informations sur la visualisation de la notification d’un nouvel atelier collectif par l'Utilisateur.\n\n
            2. Utilisation des données\n
              \t2.1 Données partagées avec les administrateurs : Dans le cadre du service en ligne, le Fournisseur de services peut partager certaines données avec les administrateurs autorisés. Les données partagées peuvent inclure les informations suivantes : statut de l'Utilisateur, type de contrat recherché, date du contrat, documents enregistrés, tableau de suivi des candidatures, ateliers et parcours vus/réalisés, ainsi que les notes des quiz de fin d'étape de parcours.\n
              \t2.2 Objectif de la collecte et du partage des données : Les données collectées et partagées sont utilisées dans le but d'améliorer l'expérience de l'Utilisateur, de fournir un suivi des activités et des progrès, d'adapter les contenus et les recommandations, et de faciliter la gestion des ateliers collectifs et des parcours de formation.\n\n
            3. Confidentialité et sécurité des données\n
              \t3.1 Confidentialité : Le Fournisseur de services s'engage à traiter toutes les données collectées avec la plus grande confidentialité et à ne pas les divulguer à des tiers non autorisés, sauf disposition contraire prévue dans le présent Contrat ou en cas d'obligation légale.\n
              \t3.2 Sécurité : Le Fournisseur de services mettra en place des mesures de sécurité appropriées pour protéger les données collectées contre tout accès, utilisation ou divulgation non autorisé(e). Cependant, il est important de noter qu'aucune méthode de transmission ou de stockage électronique n'est totalement sécurisée et que le Fournisseur de services ne peut garantir une sécurité absolue des données.\n\n
            4. Durée et résiliation\n
              \t4.1 Durée : Ce Contrat est valable dès l'acceptation des conditions d'utilisation du service en ligne et reste en vigueur pendant toute la durée de l'utilisation du service.\n
              \t4.2 Résiliation : Le Contrat peut être résilié par l'Utilisateur à tout moment en cessant d'utiliser le service en ligne. Le Fournisseur de services se réserve également le droit de résilier ce Contrat et de cesser la collecte des données à sa seule discrétion, moyennant un préavis raisonnable.\n\n
            5. Consentement\n
              \tEn utilisant le service en ligne fourni par le Fournisseur de services, l'Utilisateur reconnaît avoir lu et compris les termes et conditions de ce Contrat et consent à la collecte, à l'utilisation et au partage des données conformément aux dispositions du présent Contrat.\n
            `}}
          </p>
        </div>
      </v-expand-transition>
      <j-curriculum-choice class="mt-4" v-if="usage.includes('curriculum')" :user="user"></j-curriculum-choice>
      <j-course-choice class="mt-4" v-if="usage.includes('courses')" v-bind:checked.sync="user.course_registration"></j-course-choice>
      <div class="wi-100 text-center">
        <v-btn type="sumbit" :disabled="!validate" :color="button.color" :class="['mt-5  primary j-btn',`v-size--${button.size}`]" >{{submitWord}}</v-btn>
      </div>
  </v-form>
  <div class="justify-center">
    <v-expand-transition>
      <v-alert v-if='errorsPresent' class="mt-5 mb-0 red lighten-2 white_ text-center">{{errorMessage}}</v-alert>
    </v-expand-transition>
  </div>
</div>
</template>

<style lang="scss" >
  #userForm{
    input[type=text],input[type=email],input[type=password],input[type=phone]{
      padding: 0;
    }
    .v-input--checkbox{
      .v-messages{
        display: none;
      }
      .v-input__slot{
        margin-bottom: 0;
      }
    } 

    .v-text-field__details{
      display: none;
    }
    form{
      width: 100%;
    }
    .display-optin {
      overflow-y: scroll;
      max-height: 30vh;
      @include phone {
        max-height: 50vh;
      }
    }
  }
</style>

<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
// For more options see below
Vue.use(VueReCaptcha, { siteKey: '6LfvtJkkAAAAAENOsgMMp1cWTNaIM5f3OGIZLl1m' })

export default {
  name: 'user-form',
  components:{
  },
  props: {
    control:{
      type: Boolean,
      required: false,
      default: ()=> {return true}
    },
    usage: {
      type: Array,
      required : false,
      default: ()=> {return ['firstname','lastname','email']}
    },
    disabled: {
      type: Array,
      required : false,
      default: ()=> {return []}
    },
    validate: {
      type: Boolean,
      default: () => {return true}
    },
    submitWord:{
      type: String,
      required: false,
      default: () => {return "Submit"}
    },
    inline: {
      type: Boolean,
      required: false,
      default: () => {return false}
    },
    solo: {
      type: Boolean,
      required: false,
      default: () => {return false}
    },
    button: {
      type: Object,
      required: false,
      default: () => {
        return {
          color: 'primary',
          size: 'x-large'
        }
      }
    },
    user: {
      type: Object,
      required: false,
      default: () => {
        return {
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          password:'',
          confirmPassword:'',
          course_registration:[],
          optin:false,
          trial:{
            isOnTrial: false,
            end: new Date()
          }
        }
      }
    }
  },
  data() {
    return {
      showPass: false,
      displayOptin: false,
      errorsPresent: false,
      errorMessage: "Une erreur est survenue, veuillez réessayer."
    }
  },
  methods: {
    checkPassword: function(){
      // let reg =  /^(?=.*[0-9])(?=.*[!@#$%^&*_-])[a-zA-Z0-9!@#$%^&*_-]{8,40}$/
      // return reg.test(this.user.password)
      return this.user.password.length > 7
    },
    checkEmail: function(){
      let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return reg.test(String(this.user.email).toLowerCase())
    },
    noError: function(){
            this.errorsPresent = false
            this.$emit('focus')
    },
    testFields: function(){
      return new Promise((resolve,reject)=>{
        if(!this.control)
          resolve()
        
        let u = this.user
        let optin = Boolean(this.user.optin)

        this.usage.map(function(el){
          if(u[el]===''){
            reject("Merci de remplir tous les champs requis.")
          }
        })

        if(this.usage.includes('email') && !this.checkEmail()){
          reject("Merci de renseigner un email valide")
        }else if(this.usage.includes('password') && !this.checkPassword()){
          // reject('Merci de renseigner un mot de passe plus fort: au moins 8 caratères, une majuscule, une minuscule, un chiffre et un caractère spécial.')
          reject('Merci de renseigner un mot de passe comportant au moins 8 caratères.')
        }else if(this.usage.includes('confirmPassword') && this.user.password != this.user.confirmPassword){
          reject("La confirmation de mot de passe n'est pas identique au mot de passe.")
        }else if(this.usage.includes('optin') && !optin || this.usage.includes('optinMJA') && !optin){
          reject("Merci d'accepter nos CGU.")
        }

        resolve('fields OK')
      })
      
    },
    onSubmit: async function() {
        try{
          await this.testFields()
          await this.$recaptchaLoaded()
          const recaptchaRes = await this.$recaptcha('login')
          this.$emit('validate', {user:this.user,recaptcha:recaptchaRes})
        }catch(e){
          this.errorMessage = e
          this.errorsPresent = true
          setTimeout(() => { this.errorsPresent = false }, 6000)
        }
    }
  },
  computed: {
    displayPass() {
      return this.showPass
    }
  }
}
</script>