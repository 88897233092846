<template>
  <div class="simple-input">
    <v-text-field 
      v-model="input" 
      v-if="!['xs','ms'].includes($vuetify.breakpoint.name) || state === 'done'" 
      ref="input"  
      :type="content.inputType" 
      :name="content.inputName" 
      :placeholder="content.placeholder"
      @keydown="handleInput($event)"  
      :disabled="state !== 'active'" 
      :error-messages="inputError ? errorMessages[content.inputName] ? [errorMessages[content.inputName]] : ['Champs invalide'] : []" 
      dense
    >
      <template v-slot:append>
        <v-icon @click="done()" :class="testInput(input) && state != 'done' ? 'valide' : 'btn-invalide'" :color="testInput(input) && state != 'done' ? 'primary' : 'transparent'" >mdi-send-circle</v-icon>
      </template>
    </v-text-field>
    <p v-else>...</p>
  </div>
</template>

<script>
import { apiURL } from '@/../config'
import axios from 'axios'

export default {
    name: 'simple-input',
    props: ['content','state','checkEntry'],
    data(){
      return {
        input: '',
        typing: true,
        typingTimeout: null,
        tryEnter: false,
        errorMessages : {
          email: 'Email invalide',
          name: 'Nom invalide',
          lastname: 'Nom invalide',
          firstname: 'Prénom invalide',
          phone: 'Numéro de téléphone invalide',
          address: 'Adresse invalide',
          study: 'Domaine invalide',
          studyLevel: 'Niveau invalide',
          experience: 'Année(s) invalide(s)',
          title: 'Titre invalide',
          begin: 'Date de début invalide',
          age: 'Age invalide',
          school: 'École invalide',
          company: 'Entreprise invalide'
        },
        loading: false
      }
    },
    mounted () {
      if (!['sm', 'xs'].includes(this.$vuetify.breakpoint.name)) this.$refs.input.focus()
      else  this.$store.dispatch('display_message_input',{placeholder: this.content.placeholder, type: this.content.inputType, cb: this.done, inputValidation: this.testInput})
    },
    computed : {
      rules () {
        return {
          email : value => {
            return this.validEmail(value)
          },
          number : value => {
            return value !== null && !!value & value.trim().length > 0
          },
          default : (value) => {
            return value !== null && !!value && value.trim().length > 1
          }
        }
      },
      entryChecks () {
        return {
          email : async value => {
            const recaptcha = await this.$recaptcha('login')
            try{ 
              this.$store.dispatch('loading')
              await axios.post(`${apiURL}public/check-email`,{ email : value, recaptcha  })
              await new Promise(( resolve ) => { setTimeout(()=>{ resolve() },500) })
            } catch (e) {
              throw { 
                error : 'duplicate', 
                cb: () => {
                  this.$store.dispatch('open_dialog', {
                    message: e.response.data.message,
                    button: `Changer d'email`,
                    alt: `Connexion`,
                    cb : () => {
                      this.input = ''
                      if (!['sm', 'xs'].includes(this.$vuetify.breakpoint.name)) this.$refs.input.focus()
                      else this.$store.dispatch('display_message_input',{placeholder: this.content.placeholder, cb: this.done, inputValidation: this.testInput})
                    },
                    altCb : () => {
                      this.$router.push('/login')
                    }
                  })
                }
              }
            } finally {
              this.$store.dispatch('stop_loading')
            }
          }
        }
      },
      inputError () {
        if(this.tryEnter) return true
        if(!this.input.length || this.typing) return false
        return !this.testInput(this.input)
      },
      testInput () {
        return this.rules[this.content.inputType] ?? this.rules.default
      }
    },
    methods : {
      handleInput (e) {
        if(e.code === "Enter" && ( !this.input.length || !this.testInput(this.input) )) return this.tryEnter = true
        this.typing = true
        this.tryEnter = false

        if (this.typingTimeout != null) clearTimeout(this.typingTimeout)
        this.typingTimeout = setTimeout( () => { this.typing = false }, 400 )

        if(e.code === "Enter" && this.state !== 'done' && !this.loading) this.done()
      },
      async done (message) {
        try{
          if(message) this.input = message
          this.$store.dispatch('hide_message_input')

          if( this.checkEntry ){ 
            this.loading = true
            await this.entryChecks[this.content.inputType](this.input)
          }
          const payload = {}
          if(!this.content.array) {
            payload[this.content.inputName] = this.content.inputName === 'email' ? this.input.trim().toLowerCase() : this.input
            this.$emit('done',payload)
          } else {
            const obj = {index: this.content.index}
            obj[this.content.inputName] = this.input
            payload[this.content.array] = [obj]
            this.$emit('done',payload,'array')
          }
        } catch (e) {
          if ( typeof e.cb !== 'undefined' ) e.cb()
          else this.$store.dispatch('open_dialog', { message: 'Une erreur est survenue, veuillez réessayer.' })
        } finally {
          this.loading = false
        }
      },
      validEmail (value) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !!value && pattern.test(value.trim().toLowerCase())
      }
    }
}
</script>

<style lang="scss">
.simple-input{
  .btn-invalide {
    visibility: hidden;
  }
}
</style>