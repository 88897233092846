// jobeden
import QualityList from './jobeden/QualityList';
import OrientationPoll from './jobeden/OrientationPoll';
import SelectionList from './jobeden/SelectionList';
// myjobacademy
import EmailDialog from './myjobacademy/EmailDialog';
import EmailDisplay from './myjobacademy/EmailDisplay';
import QuizCreator from './myjobacademy/QuizCreator';

export default {
    install(Vue){
        Vue.mixin({
            created() {
                const jcomp = {QualityList,OrientationPoll,SelectionList,EmailDialog,EmailDisplay,QuizCreator}
                for(let key in jcomp){
                    Vue.component('j'+key,jcomp[key]);
                }
            }
        });
    }
}
