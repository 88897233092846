<template>
  <div :class="['message', author]">
      <div class="profile">
          <profile-bubble v-if="author != 'main'" :image="author === 'self' ? $store.getters.getPicture && $store.getters.getPicture : '/images/coach-sample.png'" :username="author === 'self' ? '' : ''"></profile-bubble>
      </div>
      <typing-bubble v-if="!introDone"></typing-bubble>
      <div v-else :class="['message--content', state]">
        <span v-for="(c,index) in content" :key="index">
            <p v-if="typeof c === 'string'" v-html="c"></p>
            <div v-else-if="typeof c === 'object'" >
                <div v-if="c.type === 'simpleInput'">
                    <simple-input @done="handleDone" :state="state" :content="c" :checkEntry="c.checkEntry" ></simple-input>
                </div>
                <div v-if="c.type === 'selectInput'">
                    <selection-input @done="handleDone($event)" :state="state" :content="c"></selection-input>
                </div>
                <div v-if="c.type === 'qualitiesInput'">
                    <quality-input @done="handleDone($event)" :state="state" :content="c" :qualities="c.qualities" :limit="c.limit" :offset="c.offset"></quality-input>
                </div>
                <div v-if="c.type === 'rankingInput'">
                    <ranking-input @done="handleDone($event)" :state="state" :content="c"></ranking-input>
                </div>
                <div v-if="c.type === 'optinInput'">
                    <opt-input @done="handleDone($event)" :state="state" :content="c"></opt-input>
                </div>
                <div v-if="c.type === 'ctaInput'">
                    <cta-input @done="handleDone($event)" :state="state" :text="c.text" :author="author"></cta-input>
                </div>
                <div v-if="c.type === 'fileInput'">
                    <file-upload @done="handleDone($event)" :state="state" :content="c" :optional="optional"></file-upload>
                </div>
                <div v-if="c.type === 'engage'">
                    <engage :title="c.title" :content="c.content"></engage>
                </div>
                <div v-if="c.type === 'more'">
                  <more-btn @done="handleDone" :state="state" :content="c"></more-btn>
                </div>
                <div v-if="c.type === 'payment'">
                    <j-register  @done="handleDone($event)" :userData="c.user" :apiRoute="getApiRoute"></j-register>
                </div>
            </div>
        </span>
        <p class="skip cursor-pointer" @click="$emit('done')" v-if="optional && state !== 'done'">Passer cette étape</p>
        <div v-if="helper" class="message--help">
          <v-icon>mdi-help-circle-outline</v-icon>
          <p>{{helper}}</p>
        </div>
      </div>
  </div>
</template>

<script>
import TypingBubble from './TypingBubble'
import simpleInput from './inputs/SimpleInput.vue'
import qualityInput from './inputs/QualityInput.vue'
import optInput from './inputs/OptInput.vue'
import rankingInput from './inputs/RankingInput.vue'
import ctaInput from './inputs/CtaInput.vue'
import fileUpload from './inputs/FileUpload.vue'
import selectionInput from './inputs/SelectionInput.vue'
import MoreBtn from './inputs/MoreBtn.vue'
import ProfileBubble from './ProfileBubble.vue'
import Engage from '../content/Engage.vue'
import { myjobacademy } from '@/../config'

export default {
    name: 'message-container',
    components: { simpleInput, qualityInput, optInput, rankingInput, ctaInput, selectionInput, fileUpload, ProfileBubble, TypingBubble, Engage, MoreBtn },
    props: ['content','author','state','optional','helper'],
    data () {
        return {
            introDone: false,
            myjobacademy: myjobacademy
        }
    },
    created () {
        const timing = this.author === 'self' ? 0 : 1000 
        setTimeout(()=>{
            this.introDone = true
            if (this.isOnlySrting) {
                setTimeout(()=>{
                    this.$emit('done')
                },500)
            }
        }, timing)
    },
    computed : {
        isOnlySrting () {
            let r = true
            for (let e of this.content) {
                if( typeof e !== "string" ){
                    r = false
                    break
                }
            } 
            return r
        },
        getApiRoute() {
            let apiRoute
            if(this.myjobacademy) apiRoute = 'myjobacademy'
            return apiRoute
        }
    },
    methods : {
        handleDone (payload, handler) {
            this.$emit('done',payload, handler)
        }
    }
}
</script>

<style lang="scss" >
    .message{
        width: 100%;
        display: flex;
        animation: inBottom 0.5s;
        animation-fill-mode: forwards;
        opacity: 0;
        &.self{
            flex-direction: row-reverse;
            .profile{
                @include phone {
                    padding-left: 0.5rem;
                }
            }
        }
        &.sender{
            @include phone{
                .profile{
                    padding-right: 0.5rem;
                }
            }
        }
        &.main{
            .message--content{
                max-width: none;
                width: 100%;
                justify-content: center;
                box-shadow: none;
                background: transparent;
            }
        }
        &--content{
            animation: inBottom 0.5s;
            animation-fill-mode: forwards;
            display: flex;
            max-width: 70%;
            width:fit-content;
            padding: 1.5em 3em;
            align-items: center;
            border-radius: 2.8rem;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
            justify-self: flex-end;
            background: white;
            flex-wrap: wrap;
            @include phone {
                max-width: calc(100% - 4rem);
                border-radius: 2rem;
                padding: 1em;
            }
            .message--help {
              cursor: help;
              position: absolute;
              top: calc(1.5em/4);
              right: calc(3em/4);
              @include phone {
                top: calc(1em/4);
                right: calc(1em/4);
              }
              &:hover p {
                display: block;
              }
              p {
                z-index: 1;
                display: none;
                position: absolute;
                width: 14rem;
                bottom: calc(100% + 0.5em);
                left: 100%;
                transform: translate(-100%,0);
                font-size: 0.7em;
                text-align: center;
                background: rgb(250, 250, 250);
                border-radius: 1em;
                border: grey 1px solid;
                padding: 1em;
                &:after {
                  z-index: 1;
                  content: "";
                  position: absolute;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 0.5em 0.5em 0.5em ;
                  border-color: transparent transparent grey transparent;
                  top: 100%;
                  left: 95%;
                  transform: translate(-95%,0) rotate(180deg);
                }
              }
            }
            p{
                margin: 0;
                text-align: left;
                white-space: pre-wrap;
            }
            .v-input{
                margin-left: 0.5em;
                margin-right: 0.5em;
                margin-top: 0;
                position: relative;
                &__slot{
                    margin-bottom: 0!important;
                }
                .v-text-field__details{
                position: absolute;
                top: calc(100% + 5px );
                width: 100%;
                }
            }
            .skip{
                position: absolute;
                left: 3rem;
                color: $grey;
                text-decoration: underline;
                font-size: 0.8em;
                bottom: 0;
                @include phone{
                    display: none;
                }
                &:hover{
                    text-decoration: none;
                }
            }
        }
        .profile{
            height: auto;
            display: flex;
            padding: 0.6rem 0.5em 0;
            @include phone{
                padding: 0;
                &-bubble{
                    height: 1.5rem;
                    width: 1.5rem;
                    span{
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }
</style>