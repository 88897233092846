<template>
  <v-navigation-drawer
    class="navigation-drawer"
    :value="value"
    @input="$emit('input', $event)"
    temporary
    app
    :width="['xs','ms'].includes($vuetify.breakpoint.name) ? '80%' : '25%'"
    :right="side === 'right'"
  >
    <i class="closeIcon" @click="$emit('input', false)"></i>
    <v-list>
      <j-drawer-item icon="home" text="Accueil" link="/"></j-drawer-item>
      <span v-for="(item, index) in contentNavigation" :key="index">
        <div v-if="item.type === 'separator' && item.isActive" class="d-flex align-center">
          <v-divider></v-divider>
            <p class="text-center ma-0 pa-3 font-weight-light text-secondary">{{item.text}}</p>
          <v-divider></v-divider>
        </div>
        <div v-else-if="item.type === 'logout' && item.isActive" class="d-flex align-center">
          <j-logout :absolute="false"></j-logout>
        </div>
        <div v-else-if="item.type === 'demo' && item.isActive" class="d-flex align-center">
          <j-demo-settings></j-demo-settings>
        </div>
        <div v-else-if="item.type === 'dropdown' && item.isActive" class="d-flex align-center">
          <v-menu transition="slide-y-transition" bottom offset-x>
            <template v-slot:activator="{ on, attrs }">
              <div class="wi-100" v-bind="attrs" v-on="on">
                <j-drawer-item :icon="item.icon" :chevron="true" :text="item.text" :class="{'v-list-item--active':isCurrent(item)}"></j-drawer-item>
              </div>
            </template>
            <v-list class="pa-0">
              <v-list-item v-for="(child, i) in item.childItems" :key="i" :to="child.link">
                <v-list-item-title>{{child.text}}</v-list-item-title>
              </v-list-item>
            </v-list>
        </v-menu>
        </div>
        <v-list-item v-else-if="item.type === 'superadmin' && item.isActive" to="/admin/import">
          <div class="list-item cursor-pointer rounded-lg d-flex wi-100">
            <v-list-item-action>
            <v-chip color="red" outlined class="he-auto text-caption rounded-lg">admin</v-chip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Importer des ressources</v-list-item-title>
            </v-list-item-content>
          </div>
        </v-list-item>
        <span v-else>
          <j-drawer-item
            v-if="item.isActive"
            :icon="item.icon"
            :text="item.text"
            :link="item.link"
          ></j-drawer-item>
        </span>
      </span>
      <span v-if="!this.$store.getters.isAuthenticated">
        <j-drawer-item
          v-if="registerApp"
          icon="check"
          text="Inscription"
          link="/register-infos"
        ></j-drawer-item>
        <j-drawer-item
          icon="login"
          text="Connexion"
          link="/login"
        ></j-drawer-item>
      </span>
    </v-list>
    <div
      v-if="this.$store.getters.isAuthenticated && logout"
      class="wi-100 d-flex justify-center flex-column flex-md-row"
    >
      <j-logout></j-logout>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { registerApp } from '@/../config'

export default {
  name: 'drawer',
  props: {
    value: {},
    contentNavigation: {
      type: Array,
      required: false
    },
    side: {
      type: String,
      required: false
    },
    logout:{
      type: Boolean,
      required: false,
      default: ()=> {return true}
    }
  },
  data() {
    return {
      registerApp: registerApp
    }
  },
  methods: {
    isCurrent(item) {
      let isCurrent = false
      item.childItems.forEach(i => {
        if(i.link === this.$route.path) isCurrent = true
      })
      return isCurrent
    }
  }
}
</script>

<style lang="scss">
.logout {
  position: absolute;
  bottom: 2em;
}
.navigation-drawer {
  padding-top: 3.5rem;
  @include closeIcon(rgb(120, 120, 120), 2rem, 0.1rem, 1rem, 1rem);
  .v-list-item {
    &::before, .v-ripple__container {
      display: none!important;
    }
    &:hover:not(.v-list-item--active) .list-item {
      background: rgba($primary, 0.3);
    }
    .list-item {
      transition: all ease 0.25s;
      margin: 6px!important;
      .v-list-item__action {
        margin: 6px 20px 6px 6px!important;
      }
      .v-list-item__content {
        padding: 6px 6px 6px 0!important;
      }
    }
  }
  .v-list-item--active {
    .list-item {
      color: white;
      background: $primary;
    }
  }
}
</style>