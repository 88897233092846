<template>
  <v-app id="inspire">
    <j-dialog-box v-if="this.$store.state.dialog.display" v-bind="this.$store.state.dialog" ></j-dialog-box>
    <j-loader v-if="this.$store.state.status.loading || this.$store.state.auth.status == 'loading' || this.$store.state.user.status == 'loading'"></j-loader>
    <transition name="popup">
    <j-pop-up v-if="$store.state.popup.display" v-bind="this.$store.state.popup"></j-pop-up>
    </transition>

    <j-drawer :contentNavigation="getContentNavigation" :logout="false"  v-model="drawer" @input="drawer = $event"></j-drawer>

    <j-nav @drawer="drawer = !drawer"></j-nav>

    <v-main>
      <v-container class="fill-height align-start pa-0" fluid >
        <transition name="fade">
          <router-view :key="$route.fullPath" />
        </transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios'
import { AUTH_LOGOUT } from "@/assets/jobtimise-common/store/actions/auth"
import { jobEden, myjobacademy, schoolApp, coach, collective, stats, demo } from '@/../config'

export default {
  name: "app",
  components: {
  },
  data:()=>{
    return {
      drawer: false
    }
  },
  created: async function() {
    document.title = this.$route.meta.title || 'MyJobAcademy Admin'
    axios.interceptors.response.use(async response => {
      if(response.headers['new-token']){
        await this.$store.dispatch('update_token',response.headers['new-token'])
      }
      return response
    }, async (error) => {
      if (error.response.status === 401) {
        this.$store.dispatch('reset_pending')
        if (this.$store.getters.isProfileLoaded) await this.$store.dispatch(AUTH_LOGOUT)
        if(!['/','/login','/forgot-password'].includes(this.$router.currentRoute.path)) this.$router.push("/")
      }
      return Promise.reject(error)
    })
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'MyJobAcademy Admin'
    }
  },
  methods:{
    drawerInput(e){
      this.drawer = e;
    }
  },
  computed: {
    getContentNavigation() {
      return [
        { type: 'separator', text: 'Gestion utilisateurs', isActive: myjobacademy && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'poll', link: '/admin/dashboard/users', text: 'Tableau de bord administrateur', isActive: myjobacademy && schoolApp && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { type: 'dropdown', icon : 'account-multiple', text: 'Utilisateurs', isActive: myjobacademy && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin, childItems: [
          { link: '/admin/users', text: 'Tous les utilisateurs' },
          { link: '/admin/users/new', text: 'Ajouter un utilisateur' }
        ]},
        { icon : 'email', link: '/admin/emails', text: 'Envoi d’emails', isActive: myjobacademy && schoolApp && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'school', link: '/admin/curriculums', text: 'Cursus et spécialités', isActive: myjobacademy && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { type: 'separator', text: 'Gestion modules des formations', isActive: myjobacademy && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'thumb-up', link: '/admin/votes/sessions', text: 'Vote des ateliers', isActive: myjobacademy && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { type: 'dropdown', icon : 'folder', text: 'Cours', isActive: myjobacademy && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin, childItems: [
          { link: '/admin/courses', text: 'Tous les cours' },
          { link: '/admin/courses/new', text: 'Ajouter un cours' },
          { link: '/admin/coaching-categories', text: 'Gérer les catégories' }
        ]},
        { type: 'dropdown', icon : 'podium-gold', text: 'Parcours', isActive: myjobacademy && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin, childItems: [
          { link: '/admin/journeys', text: 'Tous les parcours' },
          { link: '/admin/journeys/new', text: 'Ajouter un parcours' }
        ]},
        { type: 'dropdown', icon : 'file', text: 'Ateliers', isActive: myjobacademy && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin, childItems: [
          { link: '/admin/sessions', text: 'Tous les ateliers' },
          { link: '/admin/sessions/new', text: 'Ajouter un ateliers' }
        ]},
        { type: 'separator', text: 'Gestion autres données', isActive: myjobacademy && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { type: 'dropdown', icon : 'camcorder', text: 'Ateliers collectifs', isActive: myjobacademy && collective && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin, childItems: [
          { link: '/admin/collectives', text: 'Tous les ateliers collectifs' },
          { link: '/admin/collectives/new', text: 'Ajouter un atelier collective' }
        ]},
        { type: 'dropdown', icon : 'file-document', text: 'Ressources', isActive: myjobacademy && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin, childItems: [
          { link: '/admin/cv', text: 'Tous les CV' },
          { link: '/admin/coverletters', text: 'Toutes les LMs' },
          { link: '/admin/hiddenoffers', text: 'Toutes les annonces cachées' }
        ]},
        { type: 'dropdown', icon : 'account-tie', text: 'Coachs', isActive: myjobacademy && coach && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin, childItems: [
          { link: '/admin/coaches', text: 'Tous les coachs' },
          { link: '/admin/coaches/new', text: 'Ajouter un coach' }
        ]},
        { icon : 'chart-line', link: '/admin/stats/myjobacademy', text: 'Statistiques', isActive: myjobacademy && stats && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { type: 'separator', text: 'Gestion admin JobEden', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'briefcase-outline', link: '/admin/jobs', text: 'Tous les jobs', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/jobs/new', text: 'Ajouter un job', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/sectors', text: 'Gérer les secteurs', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/job-categories', text: 'Gérer les catégories', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { icon : 'chart-bubble', link: '/admin/qualities', text: 'Toutes les qualités', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { link: '/admin/quality-categories', text: 'Gérer les catégories', isActive: jobEden && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin },
        { type: 'demo', isActive: demo && this.$store.getters.isAuthenticated && this.$store.getters.isAdmin},
        { type: 'superadmin', isActive: this.$store.getters.isAuthenticated && this.$store.getters.isAdmin && this.$store.getters.isSuperAdmin},
        { type: 'logout', isActive: this.$store.getters.isAuthenticated },
      ]
    }
  }
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@import 'assets/jobtimise-common/scss/style.scss';

#inspire {
  width: 100%;
  font-family: $titleFont, Helvetica, Arial, sans-serif;
}
.v-application--wrap{
  background: $mainGradient;
}
</style>
