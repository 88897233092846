<template>
  <j-page-template class="homepage" column vertcenter>
    <template v-slot:content>
      <div class="screen-min-height flex-grow-1 d-flex flex-column align-center justify-center">
        <div v-if="$store.getters.isAuthenticated" class="d-flex flex-column">
          <div class="d-flex flex-column flex-md-row justify-space-around align-center">
            <div class="wi-80 wi-sm-100 font-weight-bold white_ d-flex flex-column justify-center align-center px-4">
              <h2 class="mb-4">Bienvenue {{$store.state.user.profile.firstname}}</h2>
              <p class="mb-4 text-center">Vous vous trouvez sur l'application administrative. Ici vous pouvez gérer les utilisateurs, leurs envoyer des mails, ajouter des ateliers, ...</p>
            </div>
          </div>
          <div class="d-flex flex-wrap mx-0 my-4 ma-md-4">
            <div v-for="(tool, index) in tools" :key="index" :class="[{'disabled':tool.disabled},'tool-wrapper']">
              <j-tool-card :tool="tool" :index="index"></j-tool-card>
            </div>
          </div>
        </div>
        <div v-else class="flex-grow-1 d-flex flex-column align-center justify-center px-2 py-10">
          <img v-if="demo" class="wi-75" :src="$store.getters.getDemoPartner.path" alt="MyJobAcademy partner demo"/>
          <img v-else-if="partner && !!$store.getters.getPartner" class="wi-75" :src="$store.getters.getPartner.path" alt="MyJobAcademy partner"/>
          <img v-else class="wi-50 wi-sm-75" alt="MyJobAcademy" src="/images/logo/jobacademy.png">
          <p class="mt-10 white_ font-weight-bold">Bienvenue, connectez-vous ci dessous.</p>
          <v-btn to="/login" class="primary white_ j-btn">se connecter</v-btn>
        </div>
      </div>
    </template>
  </j-page-template>
</template>

<script>
import { schoolApp, collective, partner, demo } from '@/../config'
export default {
  name: 'Home',
  data() {
    return {
      partner: partner,
      demo: demo
    }
  },
  computed: {
    tools() {
      return [
        { title: 'Tableau de suivi des utilisateurs', text: 'Ajoutez des utilisateurs, modifiez-les ou encore regardez leurs profils pour suivre leurs avancées.', btn: 'voir les utilisateurs', link: '/admin/users', img: 'users-table' },
        { title: 'Statistiques sur l’emploi de vos utilisateurs', text: 'En un coup d’œil, vous pouvez savoir combien d’utilisateurs sont encore en recherche, n’ont pas commencé ou encore ceux dont le contrat se termine bientôt.', btn: 'voir le tableau de bord', link: '/admin/dashboard/users', img: 'dashboard', disabled: !schoolApp },
        { title: 'Communiquez avec eux via la plateforme', text: 'Envoyez des mails à plusieurs utilisateurs. Pour leur rappeler de remplir leur tableau de candidatures, de suivre les parcours, ... Avec des corps déjà rédigés.', btn: 'envoyer emails', link: '/admin/emails', img: 'user-profile' },
        { title: 'Votes des ateliers ', text: 'Les ateliers qui intéressent vos utilisateurs sont comptabilisés afin de connaître leurs besoins.', btn: 'voir les votes', link: '/admin/votes/sessions', img: 'votes' },
        { title: 'Ateliers collectifs', text: 'Organisez des ateliers collectifs en choisissant la date, l’heure et l’organisateur(rice).', btn: 'gérer les ateliers', link: '/admin/collectives', img: 'group', disabled: !collective },
        { title: 'Gérez les parcours', text: 'Gestionnez le contenu des parcours, ateliers, quiz ou étapes. Tout est personnalisable.', btn: 'voir les parcours', link: '/admin/journeys', img: 'woman-computer' }
      ]
    }
  }
}
</script>

<style lang="scss">
.homepage {
  .tool-wrapper {
    margin: calc(10% / 2 / 2);
    width: 45%;
    @include smallPhone {
      margin: calc(10% / 2);
      width: 90%;
    }
    @include noPhone {
      margin: calc(10% / 3 / 2);
      width: 30%;
    }
    &.disabled {
      display: none;
    }
  }
}
</style>