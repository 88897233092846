<template>
    <div :class="['page-template screen-min-height wi-100 d-flex',{'pb-10':pb},{'flex-column':column},{'paddingTNAv':hasnav},{'align-center':vertcenter},{'justify-center':center},{'main-gradient':background==='main-gradient'},{'BGblueImage':background==='blueBG'},{'BGgreyImage':background==='greyBG'},{'BGwhite-opacity-3':background==='BGwhite-opacity'}]">
        <slot name="content"></slot>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'page-template',
  props:{
      vertcenter: Boolean,
      center: Boolean,
      background: String,
      hasnav: Boolean,
      column: Boolean,
      pb: Boolean
  },
  data(){
      return {
      }
  },
  mounted(){
      
  },
  methods:{
  }
}
</script>

<style lang="scss">
</style>
