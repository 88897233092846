<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="white rounded-lg pa-4 mb-4">
      <v-select :value="activeSubject" @change="$emit('changeSubject', $event)" :items="subjects" item-text="description" return-object label="Sujet *" hide-details></v-select>
      <v-textarea v-model="signature" label="Signature du mail *" placeholder="Votre signature" class="mt-6" rows="2" outlined persistent-placeholder hide-details></v-textarea>
    </div>
    <div class="flex-grow-1 white rounded-lg pa-4 d-flex flex-column">
      <div v-if="activeSubject.slug === 'blank'">
        <v-text-field v-model="custom.subject" label="Objet du mail *" placeholder="🚀 Objet personnalisé" persistent-placeholder hide-details></v-text-field>
        <p class="mb-0 mt-6">Cher/Chère [nom de l’étudiant],</p>
        <v-textarea v-model="custom.body" label="Corps du mail *" placeholder="Corps personnalisé" class="my-4" rows="12" outlined persistent-placeholder hide-details></v-textarea>
      </div>
      <div v-else>
        <div class="border-grey-b pb-2 mb-4">
          <span>Objet : {{activeTemplate.subject}}</span>
        </div>
        <p class="text-pre-wrap">{{`${activeTemplate.content}\n\n${signature ? signature : '[Votre signature]'}`}}</p>
      </div>
      <v-btn @click="$store.dispatch('open_dialog',{title: 'Confirmation d’envoi :', message: confirmationInfos, alt: 'annuler', button: 'envoyer', cb: ()=>{send()}})" :disabled="!validate" class="j-btn primary white_" depressed>envoyer</v-btn>
    </div>
    <v-snackbar class="mb-8" color="green" v-model="success">
      <p class="ma-0 white_">{{counter === 1 ? `Email envoyé à l'utilisateur.` : `Emails envoyés à ${counter} utilisateurs.`}}</p>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" v-bind="attrs" text @click="success = false">Fermer</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import emailTemplates from '@/assets/mail-templates/revivalUsers'
import { apiURL } from '@/../config'
import axios from 'axios'

export default {
  props: ['solo', 'users','selected','hasMore','selectAll','activeSubject','selectNbr','hasCurriculum','hasSpeciality','curriculumActive','specialityActive','filters'],
  name: 'email-display',
  data() {
    return {
      success: false,
      counter: 0,
      signature: `${this.$store.getters.getLastname} ${this.$store.getters.getFirstname}\n${this.$store.getters.getEmail}`,
      subjects: [
        {slug: 'journey', filter: 'noprogress=true', description: 'Qui n’ont pas terminé leur parcours'},
        {slug: 'resume', filter: 'noresumes=true', description: 'Qui n’ont pas créé de CV'},
        {slug: 'contractNear', filter: 'state=found&soon=true', description: 'Dont le contrat se termine dans moins de 3 mois'},
        {slug: 'searching', filter: 'state=searching', description: 'Qui sont encore en recherche'},
        {slug: 'logbook', filter: 'nocandidatures=true', description: 'Qui n’ont pas rempli leur tableau de candidatures'},
        {slug: 'activation', filter: 'noactivate=true', description: 'Qui n’ont pas activé leur compte'},
        {slug: 'blank', filter: '', description: 'Email personnalisé'}
      ],
      custom: {
        subject: '',
        body: ''
      }
    }
  },
  computed: {
    activeTemplate() {
      return emailTemplates[this.activeSubject.slug]
    },
    validate() {
      return this.activeSubject && this.signature && (this.selected.length || this.selectAll)
    },
    confirmationInfos() {
      if(this.solo) return `Envoyer ce mail à ${this.users[0].email} ?`
      else return `Envoyer ces mails aux ${this.selectNbr} utilisateurs ${this.hasCurriculum ? `en ${this.curriculumActive.title.toLowerCase()} ${this.hasSpeciality ? `(${this.specialityActive.title.toLowerCase()})` : ''}` : ''} ${this.activeSubject.description.toLowerCase()} ?`
    }
  },
  methods: {
    async send() {
      try {
        const selectedLean = this.selected.map(u => { return { _id: u._id }})
        const res = await axios.post(`${apiURL}auth/admin/send-emails?selectall=${this.hasMore&&this.selectAll}&skip=${this.users.length}${this.filters}`, {users: selectedLean, signature: this.signature, slug: this.activeSubject.slug, custom: this.custom })
        this.success = true
        this.counter = res.data.counter
      } catch (err) {
        this.$store.dispatch('open_dialog',{message: err.response.data.message})
      }
    }
  }
}
</script>

<style>

</style>