<template>
    <div class="mt-4 wi-100 d-flex justify-space-around">
        <div class="wi-45 qualityContainer">
            <j-quality-list :value="activeArray || []" @add="handleAdd($event,activeItem)" :max_qualities="max_qualities"></j-quality-list>
        </div>
        <div class="wi-45 qualityContainer">
            <div class="d-flex align-center justify-space-between my-1" v-for="(item,i) in activeItemArray" :key="item._id">
                <p v-if="jobQualities" class="mr-2 mb-0 wi-50 flex-grow-0"><strong>{{item.quality.title}} </strong></p>
                <v-text-field v-if="jobQualities" class="wi-20 flex-grow-0 mr-auto" append-icon="%" v-model.number="item.score"></v-text-field>
                <p v-if="qualityCat" class="mr-2 mb-0 wi-50 flex-grow-0"><strong>{{item.title}} </strong></p>
                <v-btn @click="activeItemArray.splice(i,1)" class="ml-10" fab small dark color="red">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.qualityContainer{
    max-height: 50vh;
    overflow: auto;
}
</style>

<script>
export default {
  name: 'show-coaching-categories',
  props: ['activeItem','max_qualities'],
  data() {
    return {
      activeItemArray: [],
      qualityCat: false,
      jobQualities: false
    }
  },
  computed:{
      activeArray(){
        if(this.jobQualities) return this.activeItemArray.map(e=>{return e.quality})
        else if(this.qualityCat) return this.activeItemArray.map(e=>{return e})
        else return []
    }
  },
  mounted() {
      if (this.activeItem.qualities) {
          this.qualityCat = true
          this.activeItemArray = this.activeItem.qualities
      }
      if (this.activeItem.composition) {
          this.jobQualities = true
          this.activeItemArray = this.activeItem.composition
      }
  },
  methods:{
    handleAdd(q,item){
        if(this.qualityCat) item.qualities.push(q)
        if(this.jobQualities)  { 
            item.composition.push({
                score: 0,
                quality: q
            })
        }
    }
  }
}
</script>