<template>
  <div :class="[{'scroll':scroll},'journeys-list']">
    <p v-if="cb" @click="$emit('cb')" class="text-grey cursor-pointer"><i class="fa fa-arrow-left"></i> Retour</p>
    <h4 class="font-weigth-bold mb-2">Progression des parcours :</h4>
    <div v-for="journey in journeys" :key="journey._id" class="pr-md-4">
        <v-divider></v-divider>
        <div class="d-flex flex-column flex-md-row align-center mb-5">
          <div class="d-flex">
            <div style="height: 45px;width:45px;" class="pa-2 rounded-circle main-gradient-pastel">
              <img class="wi-100" src="/images/3d/student.png" alt="Étudiant"/>
            </div>
            <div class="ml-3">
              <p class="mb-0 h4 secondary--text">{{journey.title}}</p>
              <p class="mb-0">Note globale : <span class="font-weight-bold">{{journey.score}}</span></p>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="d-flex mt-4 mt-md-0">
            <a v-if="adminApp" target="_blank" :href="`${myjobacademyFront}/journeys/${journey._id}`"><v-btn class="j-btn red-gradient white--text">Voir</v-btn></a>
            <v-btn v-else @click="$router.push('/journeys/'+journey._id)" class="j-btn red-gradient white--text">{{journey.percentage === 0 ? 'Commencer' : journey.percentage === 100 ? 'Recommencer' : 'Continuer'}}</v-btn>
          </div>
        </div>
        <v-progress-linear class="mb-5 rounded-pill" :value="journey.percentage" color="primary" height="20" >
        <template v-slot:default="{ value }">
            <p class="ma-0 caption white--text">{{ Math.ceil(value) }}% du parcours</p>
        </template>
        </v-progress-linear>
        <div class="d-flex flex-row flex-wrap justify-start steps-grid">
            <div v-for="(step, index) in journey.timeline" :key="index" :class="[{'large':large},'step white rounded-lg elevation-2 d-flex flex-column justify-center align-center mb-5 pa-2']">
                <j-circular-progress :class="[lgText&&'largeText','mb-5']" :value="Math.ceil(step.percentage)"></j-circular-progress>
                <p class="text-center">{{step.title?(index+1)+' - '+step.title:'Étape '+(index+1)}}</p>
                <div v-if="step.quiz && step.quiz.length">
                  <p>Quiz : {{step.quiz_result}}/{{step.quiz.length}}</p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { admin, myjobacademyFront } from '@/../config'

export default {
    name: 'journeys-list',
    props: {
      journeys: { type: Array },
      lgText: { type: Boolean },
      cb: { type: Boolean },
      scroll: { type: Boolean, default: ()=>{return true} },
      large: { type: Boolean, default: ()=>{return false} }
    },
    data() {
        return {
          myjobacademyFront: myjobacademyFront,
          adminApp: admin
        }
    }
}
</script>

<style lang="scss">
.journeys-list {
    &.scroll {
      @include noPhone {
        overflow: hidden;
        overflow-y: scroll;
        height: 100%;
      }
    }
    .circular-progress{
        height: 6.3rem!important;
        width: 6.3rem!important;
        .front, .back { 
            stroke-width: 0.4rem!important; 
        }
    }
    .largeText {
        font-size: 1.25em!important;
    }
    .steps-grid {
        .step {
          &.large {
            $margin: calc(10% / 2 / 2);
            width: 45%;
            margin: 0 $margin $margin $margin;
            @include noPhone {
              $margin: calc(10% / 6 / 2);
              width: 15%;
              margin: 0 $margin $margin $margin;
            }
          }
          &:not(.large) {
            $margin: calc(10% / 2 / 2);
            width: 45%;
            margin: 0 $margin $margin $margin;
            @include noPhone {
              $margin: calc(10% / 3 / 2);
              width: 30%;
              margin: 0 $margin $margin $margin;
            }
          }
        }
    }
}
</style>