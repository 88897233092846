<template>
  <router-link :to="tool.link" class="tool-card text-grey rounded-lg d-flex flex-column elevation-1 he-100 wi-100">
    <h5 class="font-weight-bold text-center mb-4">{{tool.title}}</h5>
    <div class="d-flex main-gradient-pastel rounded-lg">
      <img class="wi-35 mx-auto" :src="`/images/3d/${tool.img}.png`"/>
    </div>
    <v-divider></v-divider>
    <p class="text-wrap text-center">{{tool.text}}</p>
    <v-spacer></v-spacer>
    <v-btn :to="tool.link" class="j-btn ma-0 text-grey">{{tool.btn}}</v-btn>
  </router-link>
</template>

<script>
export default {
  props: ['tool','index'],
  name: 'tool-card'
}
</script>

<style lang="scss">
.tool-card {
  position: relative;
  padding: 16px;
  background-color: white;
  p {
    margin-bottom: calc(36px + 16px);
  }
  .j-btn {
    position: absolute;
    bottom: 16px;
    left: 50%;
    width: calc(100% - 32px);
    transform: translateX(-50%);
    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 9999px;
      background-image: $redGradient;
      z-index: -1;
      opacity: 0;
      transition: opacity linear 0.5s;
    }
  }
  &, img, p, .j-btn span {
    transition: all ease 0.5s;
  }
  &:hover {
    transform: translateY(-0.75rem);
    .j-btn span {
      color: white;
    }
    .j-btn::before {
      opacity: 0.75;
    }
  }
}
</style>