<template>
  <div class="profile-bubble" :style="style">
      <span v-if="!image">{{username ? username[0] : 'vous'}}</span>
  </div>
</template>

<script>
export default {
    name: 'profile-bubble',
    props: ['image', 'username'],
    computed : {
        style () {
            return this.image ? 'background-image : url(' + this.image + ')' : ''
        }
    }
}
</script>

<style lang="scss">
    .profile-bubble{
        $bubbleH : 3.5rem;
        background-size: cover;
        background-position: center;
        width: $bubbleH;
        height: $bubbleH;
        aspect-ratio: 1;
        background-color: rgba(white, 0.3);
        border-radius: 100%;
        display: flex;
        align-items: center ;
        justify-content: center;
        @include phone{
            $bubbleH: 2.5rem;
            height: $bubbleH;
            width: $bubbleH;
        }
        span{
            text-transform: capitalize;
            color: white;
            font-weight: 300;
            font-size: 1.25rem;
            @include phone{
                font-size: 1rem;
            }
        }
    }
</style>