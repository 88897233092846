    // Content
import ContentLoader from './components/content/ContentLoader'
import PageTemplate from './components/content/PageTemplate'
import Engage from './components/content/Engage'
import DemoSettings from './components/content/DemoSettings'
import HelperChat from './components/content/HelperChat'
    // Utils
import DialogBox from './components/utils/DialogBox'
import DatePicker from './components/utils/DatePicker'
import Loader from './components/utils/Loader'
import PdfViewer from './components/utils/PdfViewer'
import UploadImage from './components/utils/UploadImage'
import PopUp from './components/utils/PopUp'
import PasswordPopup from './components/utils/PasswordPopup'
import BannerInfos from './components/utils/BannerInfos'
import DirectUpload from './components/utils/DirectUpload'
import CollectivesInfos from './components/utils/CollectivesInfos'
    // Navigation
import DrawerItem from './components/navigation/DrawerItem'
import Drawer from './components/navigation/Drawer'
import Nav from './components/navigation/Nav'
    // User
import CourseChoice from './components/user/CourseChoice'
import CurriculumChoice from './components/user/CurriculumChoice'
import LoginDialog from './components/user/LoginDialog'
import LoginForm from './components/user/LoginForm'
import Logout from './components/user/Logout'
import RegisterForm from './components/user/RegisterForm'
import UserForm from './components/user/UserForm'
import ProfileBubble from './components/chat/ProfileBubble'
    // Video
import VideoCarousel from './components/video/VideoCarousel'
import VideoThumbnail from './components/video/VideoThumbnail'
import VimeoEmbed from './components/video/VimeoEmbed'
import YoutubeEmbed from './components/video/YoutubeEmbed'
    // Payment
import CardInfos from './components/payment/CardInfos'
import Register from './components/payment/Register'
    //Jobs
import JobDescription from './components/jobs/JobDescription'
    //Dataviz
import CircularProgress from './components/dataviz/CircularProgress'
import LinearProgress from './components/dataviz/LinearProgress'
import JourneysList from './components/dataviz/JourneysList'
import CandidaturesList from './components/dataviz/CandidaturesList'
import ToolCard from './components/dataviz/ToolCard'
import SelectDocuments from './components/dataviz/SelectDocuments'
    //Chat
import ChatEngine from './components/chat/ChatEngine'
import ChatFullVideo from './components/chat/ChatFullVideo'
import ChatSteps from './components/chat/Steps'

export default {
    install(Vue){
        Vue.mixin({
            created() {
                const jcomp = {ChatEngine,ChatFullVideo,ChatSteps,LinearProgress,CircularProgress,JourneysList,CandidaturesList,ToolCard,SelectDocuments,ContentLoader,CourseChoice,CurriculumChoice,DatePicker,DialogBox,Engage,DemoSettings,HelperChat,PopUp,PasswordPopup,BannerInfos,DirectUpload,CollectivesInfos,Drawer,DrawerItem,JobDescription,Loader,LoginDialog,LoginForm,Logout,Nav,PageTemplate,PdfViewer,ProfileBubble,RegisterForm,UploadImage,UserForm,VideoCarousel,VideoThumbnail,VimeoEmbed,YoutubeEmbed,CardInfos,Register}
                for(let key in jcomp){
                    Vue.component('j'+key,jcomp[key])
                }
            }
        })
    }
}
