<template>
    <v-row class="fill-height pa-10"  >
        <v-col cols="12" md="6"> 
            <v-card class="qualityContainer">
                <v-card-title>Liste des qualités</v-card-title>
                <v-card-text>
                    Sélectionnez, classez, c'est magique. Découvrez le résultat.
                </v-card-text>
                <j-quality-list :value="ballot" @add="addQuality($event)" :max_qualities="max_qualities"></j-quality-list>
            </v-card>
        </v-col>
        <v-col cols="12" md="6">
            <v-card>
                <v-card-title>{{self? 'Vos' : 'Les'}} qualités sélectionnées</v-card-title>
                <v-card-text>20 000 annonces d'emploi pour trouver les jobs qui collent à votre personnalité.</v-card-text>

                <div class="d-flex pa-5">
                    <draggable :value="ballot" @change="handleChange($event)"  @start="drag = true" v-bind="dragOptions" @end="drag = false"  ghost-class="ghost" class="flex-grow-1 px-1">
                        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                            <v-list-item v-for="(quality,i) in ballot" class="box-shadow-1 my-2 rounded-pill pa-2 px-5   " :key="quality._id">
                                <v-list-item-title>{{i + 1}}. {{quality.title}}</v-list-item-title>
                                <v-btn icon color="primary">
                                    <v-icon>mdi-arrow-up-down-bold</v-icon>
                                </v-btn>
                            </v-list-item>
                        </transition-group>
                    </draggable>
                    <div class="d-flex flex-column flex-grow-0" >
                        <v-list-item v-for="(quality,i) in ballot" :key="quality._id">
                            <v-btn icon color="error" @click="unselectQuality(i)">
                                <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                        </v-list-item>
                    </div>
                </div>
                <v-row class="fill-width  pa-5" justify="center">
                    <v-btn @click="$emit('submit')" :disabled="(ballot.length < 7)" color="primary">Valider</v-btn>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>

<style lang="scss" scoped>
    .qualityContainer{
        max-height: 70vh;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .v-list{
            flex: 1;
            overflow: auto;
        }
    }
    .v-list-item{
        min-height: 0;
        background: white;
        &__title{
                text-transform: capitalize;  
        }
    }
    .ghost {
        opacity: 0.5;
        background: rgba($secondary,0.5);
    }
</style>


<script>
import draggable from 'vuedraggable';

export default {
    name: 'orientation-poll',
    components:{
        draggable
    },
    data(){
        return {
            drag: false,
            max_qualities : 7
        }
    },
    props:['ballot','self','user'],
    async created(){
     },
    computed:{
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    methods:{
        addQuality(q){
            this.ballot.push(q);
        },
        handleChange(e){
            const {moved} = e;
            const el = this.ballot[moved.oldIndex];
            this.ballot.splice(moved.oldIndex,1);
            this.ballot.splice(moved.newIndex,0,el);
        },
        unselectQuality(i){
            this.ballot.splice(i,1);
        },
    }
}
</script>