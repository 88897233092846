<template>
    <v-list v-if="leftQualities.length">
        <template v-for="(quality,j) in leftQualities">
            <v-list-item :key="quality._id" class="py-1 wi-100">
                <v-list-item-title>{{quality.title}}</v-list-item-title>
                <v-btn icon color="primary" @click="selectQuality(quality)">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-list-item>
            <v-divider class="ma-0" :key="j"></v-divider>
        </template>       
    </v-list>
</template>

<style lang="scss" scoped>
.v-list-item{
    min-height: 0;
    background: white;
    &__title{
        text-transform: capitalize;
        &::first-letter{
            text-transform: uppercase;  
        }
    }
}
</style>

<script>
import { apiURL } from '@/../config'
import axios from 'axios'


export default {
    name: 'quality-list',
    props: ['value','max_qualities'],
    data(){
        return {
            qualities:[]
        }
    },
    async created(){
        const cat = await axios.get(`${apiURL}auth/admin/qualities`)
        this.qualities = cat.data.qualities
     },
    computed:{
        leftQualities(){
            if(this.qualities.length > 0){
                return this.qualities.filter(q=>{
                    return this.value.findIndex(e=>{return e._id == q._id}) == -1
                })
            }else{ return [] }
        }
    },
    methods:{
        selectQuality(q){
            if(this.value.length > this.max_qualities - 1 ){
                this.$store.dispatch("open_dialog",{message:`Juste ${this.max_qualities} qualités svp. Même si nous savons que vous en avez des tas d'autres.`, button: "Fermer"})
            }else{ this.$emit('add',q) }
        }
    }
}
</script>