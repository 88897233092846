<template>
  <div class="select-documents main-gradient-pastel pa-4 text-grey">
    <div class="mb-4 d-flex align-center">
      <img class="icon-img-3" src="/images/3d/folder.png" alt="Dossier"/>
      <h5 class="mb-0 ml-4 text-grey ">{{content.title}} :</h5>
    </div>
    <div class="white rounded-lg pa-4 d-flex flex-column">
      <v-tabs v-model="tab" v-if="$vuetify.breakpoint.mdAndUp && !admin" background-color="transparent" class="mb-8 flex-grow-0" grow>
        <v-tab>Vos {{content.plurial}}</v-tab>
        <v-tab>{{content.new}}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div v-if="$vuetify.breakpoint.smAndDown && !admin" class="d-flex flex-column mb-4">
            <v-btn outlined class="ma-0 ml-auto primary--text j-btn" @click="tab++">{{content.new}}<v-icon right>mdi-arrow-right</v-icon></v-btn>
          </div>
          <div v-if="loading" class="d-flex flex-column text-caption align-center my-10">
            <p class="wi-100 text-center">Chargement des documents ...</p>
            <v-progress-linear class="wi-50" color="secondary" indeterminate rounded height="6"></v-progress-linear>
          </div>
          <div v-else class="d-flex flex-column">
            <v-slide-group v-if="files[content.slugDB].length" v-model="fileIndex" arrows>
              <v-slide-item v-for="(file, index) in files[content.slugDB]" :key="file._id">
                <div @click="fileIndex = index" :class="[{'active':fileIndex === index},'file-card ma-4 d-flex flex-column']">
                  <img class="elevation-3" :src="file.thumbnail" :alt="file.title"/>
                  <p class="ma-0 mt-2 h5 text-center text-pre-wrap">{{file.title}}</p>
                </div>
              </v-slide-item>
            </v-slide-group>
            <p v-else class="my-10 text-center">{{userData.noData}}</p>
            <v-btn @click="save()" class="my-2 j-btn red-gradient white_">valider</v-btn>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div v-if="$vuetify.breakpoint.smAndDown && !admin" class="d-flex flex-column mb-4">
            <v-btn outlined class="ma-0 mr-auto primary--text j-btn" @click="tab--"><v-icon left>mdi-arrow-left</v-icon>vos {{content.plurial}}</v-btn>
          </div>
          <div class="wi-90 wi-sm-100 mx-auto d-flex flex-column flex-md-row align-center justify-center main-gradient-pastel rounded-lg pa-2 mb-4">
            <img class="upload-img mx-2" src="/images/3d/upload.png" alt="Upload"/>
            <p class="mb-0 text-grey mx-2 text-center text-md-start">Vos documents accessibles n'importe où.</p>
          </div>
          <v-form @submit.prevent="submit()" class="wi-90 wi-sm-100 mx-auto d-flex flex-column border-grey pa-4 rounded-lg">
            <v-file-input label="Votre fichier en pdf *" v-model="document" @change="changeDoc()" accept="application/pdf" class="mb-4" dense hide-details></v-file-input>
            <v-text-field v-model="newFile.title" label="Titre du fichier *" class="mb-4" hide-details></v-text-field>
            <v-btn :loading="loading" :disabled="loading || !newFile.type || !newFile.title || !document" type="submit" class="j-btn mt-4 primary white_">enregistrer</v-btn>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
      <v-btn @click="$emit('close')" class="mt-2 j-btn text-grey" text>annuler</v-btn>
    </div>
  </div>
</template>

<script>
import { apiURL, admin } from '@/../config'
import axios from 'axios'

export default {
  props: ['type','resume','letter'],
  name: 'select-documents',
  data() {
    return {
      admin: admin,
      loading: true,
      tab: 0,
      fileIndex: null,
      files: {
        resumes: [],
        coverLetters: []
      },
      newFile: {},
      newId: '',
      document: null
    }
  },
  created() {
    this.getFiles()
  },
  methods: {
    async getFiles() {
      try {
        const res = await axios.get(this.userData.api)
        this.files = res.data
        const id = this.newId && this.newId || this[this.type] && this[this.type]._id
        this.fileIndex = this.files[this.content.slugDB].findIndex(f => { return f._id === id })
        this.newFile.type = this.content.enum
        this.loading = false
      } catch(e) {
        this.$store.dispatch('open_dialog',{message:'Erreur lors de la recherche de vos documents', button:'Rafraichir', cb:()=>{this.$router.go()}})
      }
    },
    save() {
      if(this.fileIndex !== this.files[this.content.slugDB].findIndex(f => { return f._id === this[this.type] && this[this.type]._id})) this.$emit('change', {type: this.type, file: this.files[this.content.slugDB][this.fileIndex]})
      this.$emit('close')
    },
    async submit() {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('document',this.document)
        formData.append('file', JSON.stringify(this.newFile))
        const res = await axios.post(`${apiURL}auth/me/files`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        this.newId = res.data.file._id
        this.tab--
        this.getFiles()
      } catch(e) {
        this.$store.dispatch('open_dialog',{message:'Erreur lors de l’enregistrement du fichier'})
      }
    },
    changeDoc() {
      if(!this.newFile.title) this.newFile.title = this.document.name.substr(0, this.document.name.lastIndexOf('.')) || this.document.name
    }
  },
  computed: {
    content() {
      if(this.type === 'resume') return {title: 'Choix du CV', plurial: 'CV', new: 'Nouveau CV', slugDB: 'resumes', enum: 'resume' }
      if(this.type === 'letter') return {title: 'Choix de la lettre de motivation', plurial: 'lettre\nde motivation', new: 'Nouvelle lettre\nde motivation', slugDB: 'coverLetters', enum: 'coverLetter' }
      else return ''
    },
    userData() {
      if(this.admin) return {noData: 'L’utilisateur n’a pas encore de document.', api: `${apiURL}auth/admin/files/users/${this.$route.params.userId}`}
      else return {noData: 'Vous n’avez pas encore de document.', api: `${apiURL}auth/me/files`}
    }
  }
}
</script>

<style lang="scss">
.select-documents {
  .v-tabs {
    border-bottom: #E0E0E0 1px solid;
    .v-slide-group__prev {
      display: none!important;  
    }
  }
  .upload-img {
    height: 15vh
  }
  .file-card {
    width: 15vw;
    @include phone {
      width: 30vw;
    }
    @include smallPhone {
      width: 50vw;
    }
    cursor: pointer;
    transition: transform 0.25s ease;
    img {
      border: transparent 5px solid;
    }
    &.active img {
      border: $primary 5px solid;
    }
    &:hover {
      transform: translateY(-0.5em);
    }
  }
}
</style>