import '@fortawesome/fontawesome-free/css/all.min.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { USER_REQUEST } from "@/assets/jobtimise-common/store/actions/user"
import Jcomponents from "@/assets/jobtimise-common"
import Acomponents from "@/components"
import vuetify from './plugins/vuetify';
import '@mdi/font/css/materialdesignicons.css'
import VueApexCharts from 'vue-apexcharts'

(async () => {
  axios.defaults.withCredentials = true
  if (store.getters.isAuthenticated) {
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token');
    store.dispatch(USER_REQUEST);
  }
  
  Vue.use(Jcomponents)
  Vue.use(Acomponents)
  Vue.use(VueApexCharts)
  Vue.component('apexchart', VueApexCharts)
  
  Vue.config.productionTip = false;

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})()
