<template>
  <div class="more-btn">
    <v-btn :disabled="state !== 'active'" @click="accepted()" :class="[content.continueClass ? content.continueClass : 'secondary white_','rounded-pill mr-2']">{{content.continue}}</v-btn>
    <v-btn :disabled="state !== 'active'" @click="refused()" class="rounded-pill ml-2" text>{{content.decline}}</v-btn>
  </div>
</template>

<script>
export default {
  name: 'more-btn',
  props: ['content','state'],
  methods: {
    accepted() {
      const payload = {}
      if (this.content.boolean) {
        if(this.content.array) {
          const obj = {index: this.content.index}
          obj[this.content.inputName] = true
          payload[this.content.array] = [obj]
          this.$emit('done',payload,'array')
        } 
        else {
          payload[this.content.inputName] = true
          this.$emit('done',payload)
        }
      } 
      else {
        payload[this.content.inputName] = 1
        this.$emit('done',payload,'counter')
      }
    },
    refused() {
      if(this.content.clear) {
        this.$parent.$emit('clear')
      } 
      else if(this.content.boolean) {
        const payload = {}
        if(this.content.array) {
          const obj = {index: this.content.index}
          obj[this.content.inputName] = false
          payload[this.content.array] = [obj]
          this.$emit('done',payload,'array')
        }
        else {
          payload[this.content.inputName] = false
          this.$emit('done',payload)
        }
      }
      else {
        this.$emit('done')
      }
    }
  }
}
</script>

<style lang="scss">
.more-btn .v-btn span {
  white-space: pre-wrap;
}
</style>