<template>
  <div class="email-dialog main-gradient-pastel text-grey d-flex flex-column pa-4">
    <div class="mb-4 d-flex align-center">
      <img class="icon-img" src="/images/3d/notification.png" alt="Notification"/>
      <h5 class="mb-0 ml-4">Envoyer un mail de relance à <span class="font-weight-bold">{{users[0].email}}</span> :</h5>
    </div>
    <j-email-display @changeSubject="changeSubject($event)" v-bind="{solo: true, users, selected, hasMore: false, selectAll: false, activeSubject, filters: ''}"></j-email-display>
  </div>
</template>

<script>
export default {
  props: ['users', 'selected'],
  name: 'email-dialog',
  data() {
    return {
      activeSubject: {slug: 'journey', description: 'Qui n’ont pas terminé leur parcours'}
    }
  },
  methods: {
    changeSubject(subject) {
      this.activeSubject = subject
    }
  }
}
</script>

<style lang="scss">
.email-dialog {
  min-height: 90vh!important;
  .icon-img {
    height: 2rem;
  }
}
</style>